/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Input, Row, Col,
   Modal, Typography, Image, Segmented, Select, message
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
// import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../../misc/Utils';
// import Enums from '../../../misc/Enums';
import {
   Grade1SummaryReport, Grade2SummaryReport, Grade3SummaryReport, Grade4SummaryReport, Grade5SummaryReport, Grade6SummaryReport, Grade7SummaryReport, Grade8SummaryReport,
   Grade9SummaryReport, Grade10SummaryReport, KinderSummaryReport,

   MTKLanguageSummaryReport, MTKScienceSummaryReport, MTKMathSummaryReport,
   MTG1LanguageSummaryReport, MTG1MathSummaryReport, MTG1RALSummaryReport,
   MTG4EnglishSummaryReport, MTG4MathSummaryReport, MTG4ScienceSummaryReport,
   MTG7EnglishSummaryReport, MTG7MathSummaryReport, MTG7ScienceSummaryReport,

   BFG12SummaryReport, PPGG12SummaryReport, GP1G12SummaryReport, GB1G12SummaryReport, FABM2G12SummaryReport, WRBSG12SummaryReport, OCG11SummaryReport, GMG11SummaryReport, RAWG11SummaryReport,
   CWG12SummaryReport, TNCTG12SummaryReport, BESRG12SummaryReport, GP2G12SummaryReport, GB2G12SummaryReport, SPG11SummaryReport, AEG12SummaryReport,
   BC12SummaryReport, CHEM212SummaryReport, DIASS12SummaryReport, OM12SummaryReport
} from '../reports';

import html2pdf from 'html2pdf.js';

const { Text } = Typography;
const { Option } = Select;

export function BStudentSummaryReports(props) {
   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [diagnosticList, setDiagnosticList] = useState([]);
   const [showSummaryReport, setShowSummaryReport] = useState(false);
   const [listLoading, setListLoading] = useState(false);
   const [downloadStatus, setDownloadStatus] = useState(false);
   const [sectionList, setSectionList] = useState(null);
   const [sectionOption, setSectionOption] = useState(0);
   const [selectedSections, setSelectedSections] = useState("-1");
   const [selectedSectionsDesc, setSelectedSectionsDesc] = useState("-1");
   const [assessmentID, setAssessmentID] = useState(null);
   const [subject, setSubject] = useState(null);
   const [subjectDesc, setSubjectDesc] = useState(null);
   const [template, setTemplate] = useState(null);

   const level = props.level;
   const type = props.type;

   useEffect(() => {
      Utils.verifyJwt();

      getDiagnosticTitles();
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<AntDIcons.SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <AntDIcons.SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: 'Diagnostic Title',
         key: 'title',
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.title}>
               <Text>
                  {
                     payload.title
                  }
               </Text>
            </Tooltip>
         ),
         width: '65%'
      },
      {
         title: 'Subject',
         key: 'subject_desc',
         ...getColumnSearchProps('subject_desc'),
         ellipsis: {
            showTitle: false,
         },
         render: payload => (
            <Tooltip placement="topLeft" title={payload.subject_desc}>
               <Text>
                  {
                     payload.subject_desc
                  }
               </Text>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: <div style={{ textAlign: 'center' }}>Report</div>,
         render: payload => (
            <>
               <div style={{ textAlign: 'center' }}>
                  <Tooltip placement='top' title={"Show Summary Report"}>
                     <AntDIcons.SolutionOutlined style={{ cursor: 'pointer' }} onClick={() => handleShowReport(payload.id, payload.subject, payload.subject_desc, payload.standard_level_name)} />
                  </Tooltip>
               </div>
            </>

         ),
         width: '15%',
      },
   ].filter(item => !item.hidden);

   const getSections = (level_id) => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${level_id}`).then((response) => {
         if (response.data) {
            const data = response.data;

            setSectionList(data);

         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getDiagnosticTitles = () => {
      setListLoading(true);

      var url = `${process.env.REACT_APP_API_BRAINEE_DIAGNOSTIC}/v3/getlist?t=${type}&l=${level}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            getSections(level);
            setDiagnosticList(data);
            setListLoading(false);
         }
      }, (error) => {
         setListLoading(false);
         console.log(error.message);
      });
   }

   const displaySummaryReport = () => {
      var assessment_id = assessmentID;

      if (showSummaryReport) {
         switch (template) {
            case 'PreKinder':
            case 'Kindergarten':
            case 'Kindergarten 1':
            case 'Kindergarten 2':
            case 'Casa':
            case 'Advance Casa':
            case 'Senior Casa':
               switch (subject) {
                  case 'mtlang':
                     return (
                        <MTKLanguageSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'mtsci':
                     return (
                        <MTKScienceSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTKMathSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'ems':
                     return (
                        <KinderSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  default:
                     return (
                        <KinderSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
               }
            case 'Grade 1':
               switch (subject) {
                  case 'mtlang':
                     return (
                        <MTG1LanguageSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'mtral':
                     return (
                        <MTG1RALSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTG1MathSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  default:
                     return (
                        <Grade1SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
               }
            case 'Grade 2':
               return (
                  <Grade2SummaryReport
                     assessment_id={assessment_id}
                     template={template}
                     section_id={selectedSections}
                     section_desc={selectedSectionsDesc}
                     diagnostic_type={props.type}
                     subject={subjectDesc}
                     subject_id={subject}
                     version={2}
                  />
               );
            case 'Grade 3':
               return (
                  <Grade3SummaryReport
                     assessment_id={assessment_id}
                     template={template}
                     section_id={selectedSections}
                     section_desc={selectedSectionsDesc}
                     diagnostic_type={props.type}
                     subject={subjectDesc}
                     subject_id={subject}
                     version={2}
                  />
               );
            case 'Grade 4':
               switch (subject) {
                  case 'mteng':
                     return (
                        <MTG4EnglishSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTG4MathSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'mtsci':
                     return (
                        <MTG4ScienceSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  default:
                     return (
                        <Grade4SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
               }
            case 'Grade 5':
               return (
                  <Grade5SummaryReport
                     assessment_id={assessment_id}
                     template={template}
                     section_id={selectedSections}
                     section_desc={selectedSectionsDesc}
                     diagnostic_type={props.type}
                     subject={subjectDesc}
                     subject_id={subject}
                     version={2}
                  />
               );
            case 'Grade 6':
               return (
                  <Grade6SummaryReport
                     assessment_id={assessment_id}
                     template={template}
                     section_id={selectedSections}
                     section_desc={selectedSectionsDesc}
                     diagnostic_type={props.type}
                     subject={subjectDesc}
                     subject_id={subject}
                  />
               );
            case 'Grade 7':
               switch (subject) {
                  case 'mteng':
                     return (
                        <MTG7EnglishSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'mtmath':
                     return (
                        <MTG7MathSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'mtsci':
                     return (
                        <MTG7ScienceSummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  default:
                     return (
                        <Grade7SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
               }
            case 'Grade 8':
               return (
                  <Grade8SummaryReport
                     assessment_id={assessment_id}
                     template={template}
                     section_id={selectedSections}
                     section_desc={selectedSectionsDesc}
                     diagnostic_type={props.type}
                     subject={subjectDesc}
                     subject_id={subject}
                     version={2}
                  />
               );
            case 'Grade 9':
               return (
                  <Grade9SummaryReport
                     assessment_id={assessment_id}
                     template={template}
                     section_id={selectedSections}
                     section_desc={selectedSectionsDesc}
                     diagnostic_type={props.type}
                     subject={subjectDesc}
                     subject_id={subject}
                     version={2}
                  />
               );
            case 'Grade 10':
               return (
                  <Grade10SummaryReport
                     assessment_id={assessment_id}
                     template={template}
                     section_id={selectedSections}
                     section_desc={selectedSectionsDesc}
                     diagnostic_type={props.type}
                     subject={subjectDesc}
                     subject_id={subject}
                     version={2}
                  />
               );
            case 'Grade 11':
               switch (subject) {
                  case 'oc':
                     return (
                        <OCG11SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'raw':
                     return (
                        <RAWG11SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'gm':
                     return (
                        <GMG11SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'stats':
                     return (
                        <SPG11SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  default:
                     return (
                        Modal.info({
                           content: 'Report not yet available.',
                           centered: true,
                        })
                     )
               }
            case 'Grade 12':
               switch (subject) {
                  case 'gp1':
                     return (
                        <GP1G12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'gb1':
                     return (
                        <GB1G12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'gp2':
                     return (
                        <GP2G12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'gb2':
                     return (
                        <GB2G12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'fabm2':
                     return (
                        <FABM2G12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'wrbs':
                     return (
                        <WRBSG12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'ppg':
                     return (
                        <PPGG12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'bf':
                     return (
                        <BFG12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'cw':
                     return (
                        <CWG12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'tnct':
                     return (
                        <TNCTG12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'besr':
                     return (
                        <BESRG12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'ae':
                     return (
                        <AEG12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'bc':
                     return (
                        <BC12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'chem2':
                     return (
                        <CHEM212SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'diass':
                     return (
                        <DIASS12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  case 'om':
                     return (
                        <OM12SummaryReport
                           assessment_id={assessment_id}
                           template={template}
                           section_id={selectedSections}
                           section_desc={selectedSectionsDesc}
                           diagnostic_type={props.type}
                           subject={subjectDesc}
                           subject_id={subject}
                           version={2}
                        />
                     );
                  default:
                     return (
                        Modal.info({
                           content: 'Report not yet available.',
                           centered: true,
                        })
                     )
               }
         }
      }
   }

   const handleDownloadReport = (report_type = 'summary') => {
      var element = null;
      var reportFileName = "";

      if (report_type === 'summary') {
         element = document.getElementById('summary-report');
         reportFileName = 'Summary Results ' + (props.type === 'pre' ? 'Pre' : 'Post') + ' Test for ' + props.template + '.pdf';
      }

      if (element !== null) {
         var opt = {
            margin: 0.5,
            filename: reportFileName,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
         };

         // New Promise-based usage:
         html2pdf().from(element).set(opt).save();
      }
   }

   const handleChange = (val) => {
      setSectionOption(val);
   }

   const handleSectionSelect = (val) => {
      var selected_sections = val.length > 0 ? val.join(",") : "";
      setSelectedSections(selected_sections);

      var section_desc = "";

      val.forEach((element) => {
         let item = sectionList.find(o => o.value === element);

         if (section_desc !== "")
            section_desc += ", ";

         section_desc += item.name;
      });

      setSelectedSectionsDesc(section_desc);
   }

   const handleShowReport = (assessment_id, subject, subject_desc, level_desc) => {
      if (selectedSections !== "" && selectedSections !== "-1") {
         setAssessmentID(assessment_id);
         setSubject(subject);
         setSubjectDesc(subject_desc);
         setTemplate(level_desc);
         setShowSummaryReport(true);
      } else {
         message.info('Please select sections to include in the report.')
      }
   }

   return (
      <>
         <div className="dashboard-item-inner3">
            <Row gutter={[10, 10]} style={{ paddingBottom: "20px" }} >
               <Col span={24}>
                  <p>Sections to be included the report</p>
                  {/* <Segmented
                     key={'uid'}
                     // value={1}
                     defaultValue={sectionOption}
                     block
                     options={[
                        {
                           label: 'All',
                           value: 0,
                        },
                        {
                           label: 'Select',
                           value: 1,
                        },
                     ]}
                     onChange={(val) => handleChange(val)}
                  /> */}
                  {
                     <Select
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Sections"
                        onChange={(val) => handleSectionSelect(val)}
                        mode="multiple"
                     >
                        {
                           sectionList !== null &&
                           sectionList.map(({ value, name }, idx) => {
                              return <Option value={value}>{name}</Option>
                           })
                        }
                     </Select>
                  }

               </Col>
               {/* <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  &nbsp;
               </Col> */}
            </Row>
            <Row gutter={[10, 10]} style={{ paddingBottom: "20px" }} >
               <Col span={24}>
                  <Table
                     rowKey={'uid'}
                     loading={listLoading}
                     size='small'
                     columns={columns}
                     dataSource={diagnosticList}
                     pagination={false}
                     scroll={{
                        x: '500px',
                        y: 'calc(100vh - 18.5rem)',
                     }}
                     footer={() =>
                        <Row style={{ width: "100%" }}>
                           <Col style={{ width: "70%" }}>{'Total Records: ' + diagnosticList.length}</Col>
                           <Col style={{ width: "30%", textAlign: "right" }}>&nbsp;</Col>
                        </Row>
                     }
                  />
               </Col>
            </Row>

         </div >

         <Modal
            key='show-diagnostic-summary-report'
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" style={{ minWidth: '30px', maxHeight: '100px' }} /> {"Summary Results  " + (props.type === 'pre' ? 'Pre' : 'Post') + " Test for " + template}</div>}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round", style: { display: 'none' } }}
            centered
            destroyOnClose={true}
            open={showSummaryReport}
            width='75%'
            style={{ maxWidth: '900px' }}
            footer={[
               <Space>
                  <Button
                     shape='round' type="primary"
                     loading={downloadStatus}
                     onClick={() => handleDownloadReport('summary')}
                  >
                     Dowload
                  </Button>
                  <Button shape='round' type='primary' onClick={() => { setShowSummaryReport(false); }}>
                     Close
                  </Button>
               </Space>
            ]}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               <div className='section_to_print'>
                  {displaySummaryReport()}
               </div>
            </div>
         </Modal>
      </>
   );
}