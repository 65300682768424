/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
// import { Layout } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import uuid from 'react-uuid';
import { UserOutlined, CloudUploadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Form, Input, Divider, Typography, Modal, Row, Col, Upload, Image, message, Radio, Space, DatePicker, Spin, Select } from 'antd';
import "./Application.scss"
import Utils from '../../misc/Utils';
import moment from 'moment';
import SectionedFormRenderer from '../../components/utility/SectionedFormRenderer';

const { Title, Text } = Typography;
const { Dragger } = Upload;

export default function Application() {
   const [form] = Form.useForm();

   const search = window.location.search;
   const query = new URLSearchParams(search);

   var tmpFN = query.get('fn') === null || query.get('fn') === '' ? '1' : query.get('fn');
   var form_number = tmpFN;

   var host = window.location.host
   var subdomain = host.split('.')[0]
   if (subdomain.indexOf("localhost") !== -1)
      subdomain = "localhost"

   const [pict, setPict] = useState(null);
   const [bc, setBC] = useState(null);
   const [rc, setRC] = useState(null);
   const [pop, setPop] = useState(null);

   const [docToView, setDocToView] = useState(null);
   const [updating, setUpdating] = useState(false);
   const [filesForUpload, setFilesForUpload] = useState([]);
   const [fileSeq, setFileSeq] = useState([]);

   const [oaData, setOAData] = useState(null);
   const [sectionedPIConfig, setSectionedPIConfig] = useState(null);
   const [formType, setFormType] = useState('basic');

   const [modalVisible, setModalVisible] = useState(false);
   const [modalDisclaimer, setModalDisclaimer] = useState(false);
   const [modalSubmit, setModalSubmit] = useState(false);

   useEffect(() => {
      getOAData();
   }, []);

   const getOAData = () => {
      var url = `${process.env.REACT_APP_API_CPH}/getoadata`;

      axios.get(url).then((response) => {
         if (response.data) {
            var data = response.data;

            setOAData({
               num_of_oaf: data.num_of_oaf,
               bank_details: JSON.parse(data.bank_details),
               testing_fee: data.testing_fee,
               online_payment: JSON.parse(data.online_payment)
            });

            //-- Make sure that the form number does not exceed the number of forms available
            if (parseInt(form_number) > data.num_of_oaf)
               form_number = '1';

            //-- Render form inputs
            getFormSectionedFields();
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const beforeDocUpload = (file, doctype) => {
      var isValid = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';

      if (!isValid) {
         message.error('You can only upload JPG and PNG files.');
      }

      var isLt = file.size / 1024 / 1024 <= 10;
      if (!isLt) {
         message.error('Image must smaller than 2MB.');
      }

      if (isValid && isLt) {
         handleUpload(file, doctype);
      }
   }

   const handleUpload = (file, doctype) => {
      const formData = new FormData();
      formData.append('file', file);

      var list = filesForUpload;
      var seqlist = fileSeq;

      //-- check if already exist
      if (seqlist.indexOf(doctype) > -1) {
         list[seqlist.indexOf(doctype)] = file;
      }
      else {
         list.push(file);
         setFilesForUpload(list);

         seqlist.push(doctype);
         setFileSeq(seqlist);
      }

      Utils.getBase64(file, imageb64 => {
         if (doctype === 'sp')
            setPict(imageb64);

         if (doctype === 'bc')
            setBC(imageb64);

         if (doctype === 'rc')
            setRC(imageb64);

         if (doctype === 'pop')
            setPop(imageb64);
      });
   };

   const renderPreviewer = (document) => {
      return <>
         <div
            className={'modal-document-viewer'}
            style={{ height: "calc(100vh - 195px)" }}
         >
            <Image
               src={document}
               style={{ minHeight: "calc(100vh - 195px)", maxHeight: "calc(100vh - 195px)" }}
            />
         </div>
      </>
   }

   const handleViewDoc = (doc) => {
      setDocToView(doc);
      setModalVisible(true);
   }

   const DoSaveApplication = () => {
      if (filesForUpload.length > 0) {
         setUpdating(true);

         var formDataUpload = new FormData();

         filesForUpload.forEach(file => {
            formDataUpload.append('files', file);
         });

         var upload_url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMultiple?s3path=${process.env.REACT_APP_S3_ONLINE_APPLICATION_PATH}`;
         axios.post(upload_url, formDataUpload).then(response => {
            if (response.data !== '') {

               var uploadedFiles = response.data.split(',');

               // var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
               let id = 'online_application_' + uuid();

               const formData = form.getFieldsValue(true);
               var other_data = {};

               // Get the other data field values
               for (const sectionKey in sectionedPIConfig) {
                  const section = sectionedPIConfig[sectionKey];
                  for (const fieldKey in section.fields) {
                     other_data[fieldKey] = formData[fieldKey];
                  }
               }

               var documents = {
                  birth_certificate: `${process.env.REACT_APP_API_S3_URL}/${subdomain}/online-application/${uploadedFiles[fileSeq.indexOf('bc')]}`,
                  report_card: `${process.env.REACT_APP_API_S3_URL}/${subdomain}/online-application/${uploadedFiles[fileSeq.indexOf('rc')]}`,
                  proof_of_payment: `${process.env.REACT_APP_API_S3_URL}/${subdomain}/online-application/${uploadedFiles[fileSeq.indexOf('pop')]}`,
               }

               var applicant_data = {
                  // id: mode === 'edit' ? applicationID : id,
                  id,
                  last_name: formData.last_name,
                  first_name: formData.first_name,
                  middle_name: formData.middle_name,
                  gender: formData.gender,
                  birth_date: moment(formData.birth_date).format('YYYY-MM-DD'),
                  email: formData.email_address,
                  contact_number: formData.contact_number,
                  other_info: JSON.stringify(other_data),
                  picture: `${process.env.REACT_APP_API_S3_URL}/${subdomain}/online-application/${uploadedFiles[fileSeq.indexOf('sp')]}`,
                  documents: JSON.stringify(documents),
                  application_status: 'pending',
                  bank_details: formData.bank_details,
                  form_number,
                  applying_for: formData.grade_level
               }

               const url = `${process.env.REACT_APP_API_CPH}/upsertonlineapplicant`;
               axios.post(url, applicant_data).then(response => {
                  console.log(response);

                  setUpdating(false);

                  if (response.data === 2) {
                     console.log('Specific condition met: response.data is 2');
                     // alert('The student you entered has already been added.');
                     // Handle the specific condition here
                     Modal.info({
                        title: 'Online Application',
                        content: 'The applicant you entered has already submitted an application.',
                        centered: true,
                     });
                  } else if (response.status === 409) {
                     console.error('The applicant with the same first and last name already exists.');
                     // alert('A student with the same first and last name already exists.');
                     Modal.info({
                        title: 'Online Application',
                        content: 'The applicant you entered has already submitted an application.',
                        centered: true,
                     });
                  } else if (response.status === 200) {
                     console.log('Student applicant added successfully.');
                     // alert('Student applicant added successfully.');
                     Modal.success({
                        title: 'Online Application',
                        content: 'Application posted.',
                        centered: true,
                     });

                     //-- Clear variables
                     form.resetFields();
                     setPict(null)
                     setBC(null);
                     setRC(null);
                     setPop(null);
                     setFileSeq([]);
                     setFilesForUpload([]);
                  } else {
                     console.error('Unexpected response:', response);
                     alert(`Unexpected response: ${response.statusText}`);
                  }
               }, (error) => {
                  setUpdating(false);
                  console.log(error.message);
                  Modal.error({
                     title: 'Online Application',
                     content: 'It seems that there was a problem encountered while processing your request.',
                     centered: true
                  });
               });

               // setFilesForUpload([]);
            } else {
               setUpdating(false);
               Modal.error({
                  title: 'Online Application',
                  content: 'It seems that there was a problem encountered while processing your request.',
                  centered: true
               });
            }
         }, (error) => {
            setUpdating(false);
            console.log(error.message);
            Modal.error({
               title: 'Online Application',
               content: 'It seems that there was a problem encountered while processing your request.',
               centered: true
            });
         });
      }
   }

   const handleSubmit = () => {
      form.validateFields().then(() => {
         setModalSubmit(true);
      }).catch((error) => {
         console.log('Validation Error:', error);
         Modal.info({
            title: 'Online Application',
            content: 'Please fill out all required fields.',
            centered: true
         });
      });
   }

   const DoSubmit = () => {
      console.log('init');
      setModalSubmit(false);
      console.log('SaveApplication');
      var canContinue = false;

      if (oaData.testing_fee !== '0')
         canContinue = pict !== null && bc !== null && rc !== null && pop !== null;
      else
         canContinue = pict !== null && bc !== null && rc !== null;

      if (canContinue) {
         DoSaveApplication();
      }
      else {
         Modal.info({
            title: 'Online Application',
            content: (<>
               Please provide the following.
               <ul>
                  {
                     pict === null &&
                     <li>
                        Picture
                     </li>
                  }
                  {
                     bc === null &&
                     <li>
                        Birth Certificate(PSA Accredited)
                     </li>
                  }
                  {
                     rc === null &&
                     <li>
                        Report Card or Diploma
                     </li>
                  }
                  {
                     pop === null && oaData.testing_fee !== '0' &&
                     <li>
                        Proof of Payment
                     </li>
                  }
               </ul>
            </>),
            centered: true
         });
      }
      // if (values.disclaimer === 'agree') {

      // }
      // else {
      //    Modal.info({
      //       title: 'Online Application',
      //       content: 'You must agree with privacy disclaimer before you can proceed.',
      //       centered: true
      //    });
      // }
   }

   function randomNumericString(length) {
      const digits = "0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
         result += digits.charAt(Math.floor(Math.random() * digits.length));
      }
      return result;
   }

   const [isProofOfPaymentVisible, setProofOfPaymentVisible] = useState(true);

   // const handleSubmit = () => {
   //    const formData = {};
   //    const filteredFormData = {};
   //    const values = form.getFieldsValue();
   //    const filteredValues = Object.fromEntries(
   //       Object.entries(values).filter(([key, value]) => value !== undefined && value !== null && value !== '')
   //    );
   //    console.log('Filtered Form values:', filteredValues);

   //    //sample for setting field values
   //    //form.setFieldsValue({ middle_name: 'YourSpecificValue' });

   //    const fieldValue1 = form.getFieldValue('last_name'); // Replace 'fieldName1' with your actual field name

   //    if (fieldValue1) {
   //       // Your logic here
   //       console.log("LastName: ", fieldValue1);
   //    }
   //    // Perform additional actions with the filtered values here
   // }

   // const [appCode, setAppCode] = useState('');
   // const [isCodeValid, setIsCodeValid] = useState(false);

   // const handleCodeCheck = () => {
   //    if (appCode === 'abc123') {
   //       setIsCodeValid(true);
   //       // setProofOfPaymentVisible(true);
   //    } else {
   //       setIsCodeValid(false);
   //       // setProofOfPaymentVisible(false);
   //    }
   // };

   const getFormSectionedFields = () => {
      var url = `${process.env.REACT_APP_API_CPH}/getsectionedfields?fn=${form_number}&ft=${formType}`;
      /*console.log(url);*/
      axios.get(url)
         .then((response) => {
            if (response.data) {
               var data = response.data;
               setSectionedPIConfig(data);
               //console.log(data);
            }
         })
         .catch((error) => {
            // setLoading(false);
            console.log(error.message);
         });
   };

   return (
      <>
         <Spin size='large' spinning={updating} tip="Submitting...Please wait.">
            <div style={{ width: '100%', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
               <div className="header-logo" >
                  <Image preview={false} src="/images/brainee_logo_new.png" height="60px" alt="BRAINEE" />
               </div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'auto', backgroundColor: '#F2F2F2' }}>
               {/* <Card
               className='custom-card'
               style={{ maxWidth: '1000px', height: '100%' }}
            > */}
               <div style={{ marginTop: '35px', marginBottom: '20px', width: '100%', maxWidth: '1000px', height: '100%', border: '1px solid #ccc', borderRadius: '7px', backgroundColor: '#FFF', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                  <div className='block-container'>
                     <div className="login-header align-left" style={{ margin: '35px 35px' }}>
                        <h3><span>APPLICATION</span> FORM</h3>
                        <p className="text-muted" style={{ fontSize: '18px' }}>Learner's Profile Form</p>
                     </div>

                     <div className='align-right' style={{ width: '165px', height: '165px', paddingTop: '35px', paddingRight: '35px' }}>
                        <Dragger
                           showUploadList={false}
                           style={{ backgroundColor: '#fff', borderRadius: '7px', padding: '0px', border: '1px solid #ccc' }}
                           beforeUpload={(file) => beforeDocUpload(file, 'sp')}
                           maxCount={1}
                           accept=".png,.jpg,.jpeg"
                        >
                           {
                              pict !== null
                                 ?
                                 <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: '#FFF',
                                    borderRadius: '7px',
                                    height: '100px',
                                 }}>
                                    {/* <Image src={questionImage} preview={false} width={'100%'} /> */}
                                    <Image src={pict} preview={false} height={'100%'} />
                                 </div>
                                 :
                                 <>
                                    <UserOutlined />
                                    <div className="ant-upload-text">Upload</div>
                                 </>
                           }
                        </Dragger>
                     </div> {/*Picture*/}
                  </div>

                  <Form
                     form={form}
                     autoComplete="off"
                     layout='vertical'
                     style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}
                  >
                     {
                        sectionedPIConfig !== null &&
                        <SectionedFormRenderer sectionedFormConfig={sectionedPIConfig} form={form} />
                     }

                     <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                        <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Upload Required Documents</Title>
                     </Divider>

                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                           {/* <ul> */}
                           <li style={{ marginBottom: '5px' }}>
                              Birth Certificate (PSA Accredited) <Space><Upload beforeUpload={(file) => { beforeDocUpload(file, 'bc') }} showUploadList={false} accept=".jpg, .png, .bmp" >
                                 <Button type="primary" size="small" shape='round'><CloudUploadOutlined /></Button>
                              </Upload>
                                 {
                                    bc !== null &&
                                    <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(bc)}><EyeOutlined /></Button>
                                 }
                              </Space>
                           </li>
                           <li>
                              Report Card or Diploma <Space><Upload beforeUpload={(file) => { beforeDocUpload(file, 'rc') }} showUploadList={false} accept=".jpg, .png, .bmp" >
                                 <Button type="primary" size="small" shape='round'><CloudUploadOutlined /></Button>
                              </Upload>
                                 {
                                    rc !== null &&
                                    <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(rc)}><EyeOutlined /></Button>
                                 }
                              </Space>
                           </li>
                           {/* </ul> */}
                        </Col>
                     </Row>

                     {isProofOfPaymentVisible && (
                        <>
                           <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                              <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Upload Proof of Payment</Title>
                           </Divider>

                           <Row gutter={[12, 12]}>
                              <Col xs={24} sm={24} md={12} lg={12}>
                                 <li style={{ marginBottom: '5px' }}>Application / Testing Fee: ₱{oaData !== null ? oaData.testing_fee : ''}</li>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12}>
                                 {/* <li style={{ marginBottom: '5px' }}>Bank Accounts (Name - Account Number):</li>

                                 {
                                    oaData !== null &&
                                    <>
                                       {
                                          oaData.bank_details !== null && oaData.bank_details !== undefined
                                             ?
                                             <Space direction='vertical'>
                                                {
                                                   oaData.bank_details.map(({ name, account_no }, idx) => {
                                                      return <li>{name} - {account_no}</li>
                                                   })
                                                }
                                             </Space>
                                             :
                                             <>NOT AVAILABLE</>
                                       }
                                    </>
                                 } */}

                              </Col>
                           </Row>
                           <Row gutter={[12, 12]} style={{ marginTop: '15px' }}>
                              <Col xs={24} sm={24} md={12} lg={12}>
                                 <li><b>Online Payment</b></li>
                                 {
                                    oaData !== null &&
                                    <>
                                       {
                                          oaData.online_payment !== null && oaData.online_payment !== undefined
                                             ?
                                             <Space direction='vertical'>
                                                {oaData.online_payment.map(({ name, url, ico }, idx) => {
                                                   return <>
                                                      <li style={{ marginBottom: '5px' }}>
                                                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                         <img src={ico} height={'20x'} width={'20px'} style={{ cursor: 'pointer' }} onClick={() => window.open(url, '_blank')} />&nbsp;
                                                         <Text style={{ cursor: 'pointer' }} onClick={() => window.open(url, '_blank')}>{name}</Text>
                                                      </li>
                                                   </>
                                                })}
                                             </Space>
                                             :
                                             <li style={{ marginBottom: '5px' }}>NOT AVAILABLE</li>
                                       }
                                    </>
                                 }
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12}>
                                 <li style={{ marginBottom: '5px' }}>
                                    Proof of Payment <Space>
                                       <Upload beforeUpload={(file) => { beforeDocUpload(file, 'pop') }} showUploadList={false} accept=".jpg, .png, .bmp" >
                                          <Button type="primary" size="small" shape='round'><CloudUploadOutlined /></Button>
                                       </Upload>
                                       {
                                          pop !== null &&
                                          <Button type="primary" size="small" shape='round' onClick={() => handleViewDoc(pop)}><EyeOutlined /></Button>
                                       }
                                    </Space>
                                 </li>
                              </Col>
                           </Row>
                           <Row gutter={[12, 12]}>
                              <Col xs={24} sm={24} md={24} lg={24}>
                                 <Form.Item
                                    name={'bank_details'}
                                    label="Payment Details (Bank name/Reference number)"
                                    rules={[{ required: oaData !== null && oaData.testing_fee !== '0' ? true : false, message: 'Payment Details Required' },]}
                                    style={{ marginBottom: '10px' }}
                                 >
                                    <Input id={'bank_details'} name={'bank_details'} style={{ borderRadius: '7px', paddingLeft: '10px' }} />
                                 </Form.Item>
                              </Col>
                           </Row>
                        </>
                     )}

                     <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px' }}>
                        <Title level={5} style={{ textAlign: "Left", color: 'green' }}>Privacy Statement</Title>
                     </Divider>

                     {/* <Row gutter={[12, 12]} style={{ padding: '15px 0px 0px 0px' }}>
                        <Col xs={24} sm={24} md={12} lg={12} style={{ display: 'flex', justifyContent: 'right' }}>
                           <Form.Item
                              name={'disclaimer'}
                              rules={[{ required: true, message: 'Privacy Disclaimer is required' }]}
                              style={{ marginBottom: '10px' }}
                           >
                              <Radio.Group
                                 id={'disclaimer'}
                                 name={'disclaimer'}
                              >
                                 <Radio value="agree">Agree</Radio>
                                 <Radio value="disagree">Disagree</Radio>
                              </Radio.Group>
                           </Form.Item>
                        </Col>
                     </Row> */}

                     <Row gutter={[12, 12]}>
                        <Col xs={24} sm={24} md={24} lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                           <i>I hereby allow Don Bosco Makati to use/post my contact details, name, email, cellphone/landline nos. and other information I provided which may be used for processing of my scholarship application, for employment opportunities and for the survey of Don Bosco Makati.</i>
                        </Col>
                     </Row>

                     <Row gutter={[12, 12]} style={{ paddingTop: '20px' }}>
                        <Col xs={24} sm={24} md={24} lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                           {/* <Text>By clicking "Submit Application", you agree to our </Text>&nbsp;<Text style={{ color: '#4ABEC7', cursor: 'pointer' }} onClick={() => setModalDisclaimer(true)}>privacy statement</Text>. */}
                           <Text>By clicking</Text>&nbsp;<Text style={{ color: '#4ABEC7', }}>Submit Application</Text><Text>, you agree to our privacy statement.</Text>

                        </Col>
                     </Row>

                     <Row gutter={[12, 12]} style={{ padding: '15px 0px 15px 0px' }}>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                           {/* <Button className='button-shadow' type='primary' shape='round' htmlType='submit'>Submit Application</Button> */}
                           <Button className='button-shadow' type='primary' onClick={handleSubmit} shape='round' htmlType="submit">Submit Application</Button>
                        </Col>
                     </Row>
                  </Form>
               </div>
               {/* </Card> */}
            </div >
            <div style={{ width: '100%', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
               Powered by CAMPUSCLOUD IT CONSULTANCY ©2024
            </div>
         </Spin>

         <Modal
            key='preview-doc'
            title={"Preview "}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            width='75%'

            onCancel={() => { setModalVisible(false); }}
            onOk={() => { setModalVisible(false); }}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               {renderPreviewer(docToView)}
            </div>
         </Modal>

         <Modal
            key='disclaimer'
            title={"Privacy Statement"}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalDisclaimer}
            width='50%'
            onCancel={() => { setModalDisclaimer(false); }}
            onOk={() => { setModalDisclaimer(false); }}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>

            </div>
         </Modal>

         <Modal
            key='confirm_submit'
            title={"Submit Application"}
            centered
            destroyOnClose={true}
            open={modalSubmit}
            handleSubmit
            footer={[
               <Button key="notpaid" type='primary' shape='round' onClick={() => DoSubmit()}>Submit</Button>,
               <Button key="close" type="primary" shape='round' onClick={() => setModalSubmit(false)}>Cancel</Button>
            ]}
         >
            <Text>REMINDER: Kindly verify your email address and contact number before submitting your application. These details are essential for effective communication during the processing of your application.</Text>
         </Modal>
      </>
   )
}
