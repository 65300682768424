import React, { useCallback } from 'react';
import { Input, DatePicker, Upload, Form, Divider, Row, Col, Typography, Radio, Button, Alert, Select } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';

const { Title } = Typography;

const defaultAttributes = {
   upload: {
      icon: CloudUploadOutlined,
      shape: 'round',
      style: { backgroundColor: '#52c41a', color: '#fff', border: 'none' },
   },
   button: {
      type: 'primary',
      size: 'small',
   },
   // Add other default attributes as needed
};

const defaultColumnAttributes = { xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 8 };

const validateInputAttributes = (attributes, fieldType) => {
   return { ...defaultAttributes[fieldType], ...attributes };
};

const validateConfigStructure = (config) => {
   if (!config || typeof config !== 'object') {
      console.error('Invalid configuration provided.');
      return false;
   }

   for (const sectionKey in config) {
      const section = config[sectionKey];
      if (!section.fields || typeof section.fields !== 'object') {
         console.error(`Invalid fields in section: ${sectionKey}`);
         return false;
      }

      for (const fieldKey in section.fields) {
         const field = section.fields[fieldKey];
         if (!field.type || typeof field.type !== 'string') {
            console.error(`Invalid field type for field: ${fieldKey} in section: ${sectionKey}`);
            return false;
         }
      }
   }

   return true;
};

const SectionedFormRenderer = React.memo(({ sectionedFormConfig, visibilityConfig, form: externalForm }) => {
   const [internalForm] = Form.useForm();
   const form = externalForm || internalForm;

   const renderField = useCallback((fieldKey, field) => {
      const inputAttributes = validateInputAttributes(field.inputAttributes, field.type);
      let fieldComponent;

      try {
         switch (field.type) {
            case 'textarea':
               fieldComponent = <Input.TextArea allowClear {...inputAttributes} />;
               break;
            case 'date':
               fieldComponent = <DatePicker allowClear style={{ width: "100%" }} {...inputAttributes} />;
               break;
            case 'upload':
               const UploadIcon = inputAttributes.icon;
               fieldComponent = (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                     <span style={{ marginRight: '8px' }}>{field.label}</span>
                     <Upload {...inputAttributes}>
                        <Button
                           icon={<UploadIcon />}
                           shape={inputAttributes.shape}
                           style={inputAttributes.style}
                        />
                     </Upload>
                  </div>
               );
               break;
            case 'radio':
               fieldComponent = (
                  <Radio.Group {...inputAttributes}>
                     {field.options.map(option => (
                        <Radio key={option.value} value={option.value}>{option.label}</Radio>
                     ))}
                  </Radio.Group>
               );
               break;
            case 'select':
               fieldComponent = (
                  <Select allowClear {...inputAttributes}>
                     {field.options.map(option => (
                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                     ))}
                  </Select>
               );
               break;
            case 'button':
               fieldComponent = (
                  <Button {...inputAttributes} type={inputAttributes.type || 'button'}>
                     {field.label}
                  </Button>
               );
               break;
            case 'text':
               fieldComponent = <Input allowClear style={{ borderRadius: '7px' }} {...inputAttributes} />;
               break;
            case 'email':
               fieldComponent = <Input allowClear style={{ borderRadius: '7px' }} type="email" {...inputAttributes} />;
               break;
            case 'header':
               fieldComponent = <Title level={5} {...inputAttributes}>{field.label}</Title>;
               break;
            default:
               console.error(`Unknown field type: ${field.type} for field: ${fieldKey}`);
               fieldComponent = <Alert message={`Unknown field type: ${field.type} for field: ${fieldKey}`} type="error" />;
         }
      } catch (error) {
         console.error(`Error rendering field: ${fieldKey}`, error);
         fieldComponent = <Alert message={`Error rendering field: ${fieldKey}. ${error.message}`} type="error" />;
      }

      return fieldComponent;
   }, []);

   if (!validateConfigStructure(sectionedFormConfig)) {
      return <Alert message="Invalid configuration provided." type="error" />;
   }

   return (
      <Form form={form} layout="vertical">
         {Object.keys(sectionedFormConfig).map((sectionKey) => {
            const section = sectionedFormConfig[sectionKey];
            let isVisible = true;
            let visibilityError = null;

            try {
               isVisible = (visibilityConfig?.[sectionKey] !== false) &&
                  (Object.keys(section.fields).some(fieldKey =>
                     visibilityConfig?.[sectionKey]?.[fieldKey] !== false &&
                     section.fields[fieldKey].visibility !== false));
            } catch (error) {
               visibilityError = `Error determining visibility for section: ${sectionKey}. ${error.message}`;
               console.error(visibilityError);
            }

            if (!isVisible) {
               return null;
            }

            const allFieldsHidden = Object.keys(section.fields).every((fieldKey) => {
               return (visibilityConfig?.[sectionKey]?.[fieldKey] === false) ||
                  (section.fields[fieldKey].visibility === false);
            });

            if (allFieldsHidden) {
               return null;
            }

            return (
               <div key={sectionKey}>
                  <Divider orientation="left" style={{ paddingBottom: '10px', paddingTop: '10px', borderTopColor: '#ccc', margin: '0px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                     <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Title level={5} style={{ textAlign: "left", color: 'green', margin: '0' }}>{section.sectionTitle}</Title>
                     </div>
                  </Divider>

                  {visibilityError && <Alert message={visibilityError} type="error" />}

                  <Row gutter={[12, 12]}>
                     {Object.keys(section.fields).map((fieldKey) => {
                        const field = section.fields[fieldKey];
                        let isFieldVisible = true;
                        let fieldVisibilityError = null;

                        try {
                           isFieldVisible = (visibilityConfig?.[sectionKey]?.[fieldKey] !== false) &&
                              (field.visibility !== false);
                        } catch (error) {
                           fieldVisibilityError = `Error determining visibility for field: ${fieldKey} in section: ${sectionKey}. ${error.message}`;
                           console.error(fieldVisibilityError);
                        }

                        if (!isFieldVisible) {
                           return null;
                        }

                        const commonProps = {
                           name: fieldKey,
                           label: field.label,
                           rules: field.rules,
                           ...field.formItemAttributes,
                        };

                        return (
                           <Col key={fieldKey} {...(field.columnAttributes || defaultColumnAttributes)}>
                              <Form.Item {...commonProps} label={field.type !== 'upload' ? field.label : null}>
                                 {fieldVisibilityError ? (
                                    <Alert message={fieldVisibilityError} type="error" />
                                 ) : (
                                    renderField(fieldKey, field)
                                 )}
                              </Form.Item>
                           </Col>
                        );
                     })}
                  </Row>
               </div>
            );
         })}
      </Form>
   );
});

export default SectionedFormRenderer;