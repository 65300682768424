import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Row, Col, Form,
   Modal, Typography, Spin, Breadcrumb, Popconfirm, Empty, Segmented
} from 'antd';
import { SearchOutlined, SaveOutlined, EditOutlined, DeleteOutlined, PlusOutlined, MenuOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import uuid from 'react-uuid';
import ProcessingModal from '../../components/processing/ProcessingModal';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
// import LeavePageBlocker from '../../components/leave-page-blocker/LeavePageBlocker';
// import FormPrompt from '../../components/leave-page-blocker/FormPrompt';
// import { Prompt } from 'react-router'
// import Prompt from '../../components/leave-page-blocker/Prompt';

const DragHandle = SortableHandle(() => (
   <MenuOutlined
      style={{
         cursor: 'grab',
         color: '#999',
      }}
   />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

export function OnlineAppInfoFields() {
   const { TextArea } = Input;
   const { Text } = Typography;
   const { Option } = Select;

   const navigate = useNavigate();
   const [form] = Form.useForm();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [groupList, setGroupList] = useState([]);
   const [fieldList, setFieldList] = useState([]);
   const [searchLoading, setSearchLoading] = useState(false);

   const [updating, setUpdating] = useState(false);
   const [editModalVisible, setEditModalVisible] = useState(false);
   const [addModalVisible, setAddModalVisible] = useState(false);

   const [group, setGroup] = useState(null);
   const [field, setField] = useState(null);
   const [fieldCode, setFieldCode] = useState(null);
   const [fieldLabel, setFieldLabel] = useState(null);
   const [fieldDesc, setFieldDesc] = useState(null);
   const [fieldShow, setFieldShow] = useState(1);
   const [fieldType, setFieldType] = useState('text');
   const [fieldRequired, setFieldRequired] = useState(1);
   const [fieldOptions, setFieldOptions] = useState([]);
   const [fieldFormType, setFieldFormType] = useState(null);
   const [fieldLocation, setFieldLocation] = useState(0);

   const [option, setOption] = useState(null);
   const [hasChanges, setHasChanges] = useState(true);

   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const onSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
         const newData = arrayMoveImmutable(fieldList.slice(), oldIndex, newIndex).filter(
            (el) => !!el,
         );
         console.log('Sorted items: ', newData);
         setFieldList(newData);
      }
   };

   const DraggableContainer = (props) => (
      <SortableBody
         useDragHandle
         disableAutoscroll
         helperClass="row-dragging"
         onSortEnd={onSortEnd}
         {...props}
      />
   );
   const DraggableBodyRow = ({ className, style, ...restProps }) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = fieldList.findIndex((x) => x.index === restProps['data-row-key']);
      return <SortableItem index={index} {...restProps} />;
   };

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      GetGroupList();
   }, []);

   // useEffect(() => {
   //    const handleBeforeUnload = (event) => {
   //       if (hasChanges) {
   //          event.preventDefault();
   //          event.returnValue = '';
   //       }
   //    };

   //    window.addEventListener('beforeunload', handleBeforeUnload);

   //    return () => {
   //       window.removeEventListener('beforeunload', handleBeforeUnload);
   //    };
   // }, [hasChanges]);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: '',
         dataIndex: 'sort',
         width: '3%',
         className: 'drag-visible',
         render: () => <DragHandle />,
      },
      {
         title: 'Field Code',
         key: 'aff_code',
         ...getColumnSearchProps('aff_code'),
         ellipsis: {
            showTitle: false,
         },
         className: 'drag-visible',
         render: payload => (
            <Tooltip placement="topLeft" title={payload.aff_code}>
               <Text>
                  {
                     payload.aff_code
                  }
               </Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Field Label',
         key: 'aff_label',
         ...getColumnSearchProps('aff_label'),
         ellipsis: {
            showTitle: false,
         },
         className: 'drag-visible',
         render: payload => (
            <Tooltip placement="topLeft" title={payload.aff_label}>
               <Text>
                  {
                     payload.aff_label
                  }
               </Text>
            </Tooltip>
         ),
         width: '17%'
      },
      {
         title: 'Message',
         key: 'description',
         ...getColumnSearchProps('description'),
         ellipsis: {
            showTitle: false,
         },
         className: 'drag-visible',
         render: payload => (
            <Tooltip placement="topLeft" title={payload.description}>
               <Text>
                  {
                     payload.description
                  }
               </Text>
            </Tooltip>
         ),
         width: '31%'
      },
      {
         title: 'Form Location',
         key: 'aff_location',
         ...getColumnSearchProps('aff_location'),
         ellipsis: {
            showTitle: false,
         },
         className: 'drag-visible',
         render: payload => (
            <Text>
               {
                  Utils.GetFormLocation(payload.aff_location)
               }
            </Text>
         ),
         width: '10%'
      },
      {
         title: 'Form Type',
         key: 'aff_form_type',
         ellipsis: {
            showTitle: false,
         },
         className: 'drag-visible',
         render: payload => (
            <Tooltip placement="topLeft" title={payload.aff_form_type === "basic" ? "This field will be shown in Basic and Full Form" : "This field will only be shown in Full Form"}>
               <Text>
                  {
                     payload.aff_form_type === "basic" ? "Basic Form" : (payload.aff_form_type !== null && payload.aff_form_type !== "" ? "Full Form" : "")
                  }
               </Text>
            </Tooltip>
         ),
         width: '10%'
      },
      {
         title: 'Required',
         key: 'required',
         ellipsis: {
            showTitle: false,
         },
         className: 'drag-visible',
         render: payload => (
            <Text>
               {
                  payload.required ? "Yes" : "No"
               }
            </Text>
         ),
         width: '10%'
      },
      {
         title: 'Action',
         key: 'action',
         className: 'drag-visible',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit Section'>
                     <EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEdit(payload.aff_id, payload.aff_code, payload.aff_label, payload.description, payload.enable, payload.data_type, payload.required, payload.option_values, payload.aff_form_type, payload.aff_location)} />
                  </Tooltip>
                  <Tooltip placement="top" title='Remove Section'>
                     <Popconfirm
                        title="Are you sure you want to remove this item?"
                        onConfirm={() => handleRemove(payload.aff_id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const GetGroupList = () => {
      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getappinfogrouplist`).then((response) => {
         if (response.data) {
            const data = response.data;

            setGroupList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const GetFieldList = () => {
      setFieldList([]);
      setSearchLoading(true);

      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getappinfofieldlist?group=${group}`).then((response) => {
         if (response.data) {
            const data = response.data;

            //-- Add index to the data for sorting
            data.forEach((item, idx) => {
               item.index = idx;
               return item;
            });

            setFieldList(data);
            setSearchLoading(false);
         }
      }, (error) => {
         setSearchLoading(false);
         console.log(error.message);
      });
   }

   const handleAdd = () => {
      form.resetFields();
      setField(null);
      setFieldCode(null);
      setFieldLabel(null);
      setFieldDesc(null);
      setFieldShow(1);
      setFieldType('text');
      setFieldRequired(1);
      setFieldOptions([]);
      setFieldFormType(null);
      setFieldLocation(0);
      setAddModalVisible(true);
   }

   const handleEdit = (id, code, name, desc, enable, type, required, option_values, form_type, location) => {
      form.resetFields();
      setField(id);
      setFieldCode(code);
      setFieldLabel(name);
      setFieldDesc(desc);
      setFieldShow(enable);
      setFieldType(type);
      setFieldRequired(required);
      setFieldFormType(form_type);
      setFieldLocation(location);

      if (option_values !== null && option_values !== undefined) {
         let tmp = [];
         let ov = JSON.parse(option_values);
         ov.forEach(item => {
            tmp.push({ option: item.value });
         });

         setFieldOptions(tmp);
      } else {
         setFieldOptions([]);
      }

      setEditModalVisible(true);
   }

   const handleSave = () => {
      if (fieldList.length > 0) {
         setUpdating(true);

         let url = `${process.env.REACT_APP_API_MANAGEMENT}/upsertappinfofields`;
         axios.post(url, fieldList).then((response) => {
            if (response.data) {
               const data = response.data;

               // GetFieldList();
               setUpdating(false);

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Application Form Management',
                     content: 'There was a problem encountered while processing your request.',
                     centered: true,
                  });
               }
               else {
                  Modal.success({
                     title: 'Application Form Management',
                     content: 'Saving successful.',
                     centered: true,
                     onOk: () => { handleCancel() },
                  });
               }
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Application Form Management',
               content: 'There was a problem encountered while processing your request.',
               centered: true,
            });
         });
      } else {
         Modal.info({
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        New Application Info Field
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   const handleUpdate = () => {
      if (fieldList.length > 0) {

         let tmp = [...fieldList];
         tmp.forEach(item => {
            if (item.aff_id === field) {
               item.aff_label = fieldLabel;
               item.aff_code = fieldCode;
               item.description = fieldDesc;
               item.enable = fieldShow;
               item.data_type = fieldType;
               item.required = fieldRequired;
               item.aff_form_type = fieldFormType;
               item.aff_location = fieldLocation;

               let tmp = [];
               fieldOptions.forEach(item => {
                  tmp.push({ value: item.option, label: item.option })
               });

               item.option_values = JSON.stringify(tmp);
            }
         });

         setFieldList(tmp);
         setEditModalVisible(false);
      } else {
         Modal.info({
            title: 'Application Form Management',
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        New Application Form Section
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   const handleCancel = () => {
      form.resetFields();
      setField(null);
      setFieldCode(null);
      setFieldLabel(null);
      setFieldDesc(null);
      setFieldShow(1);
      setFieldType('text');
      setFieldRequired(1);
      setFieldOptions([]);
      setFieldFormType(null);
      setFieldLocation(0);
      setEditModalVisible(false);
      setAddModalVisible(false);
   }

   const handleRemove = (id) => {
      if (fieldList.length > 0) {

         let url = `${process.env.REACT_APP_API_MANAGEMENT}/removeappinfofield?id=${id}`;
         axios.post(url).then((response) => {
            if (response.data) {
               const data = response.data;

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Application Form Management',
                     content: 'There was a problem encountered while processing your request.',
                     centered: true,
                  });
               }
               else {
                  Modal.success({
                     title: 'Application Form Management',
                     content: 'Remove successful.',
                     centered: true,
                     onOk: () => { handleCancel() },
                  });
               }

               const tmp = fieldList.filter(item => item.aff_id !== id);
               setFieldList(tmp);
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Application Form Management',
               content: 'There was a problem encountered while processing your request.',
               centered: true,
            });
         });
      }
   }

   const handleAddField = () => {
      let tmp = {
         aff_id: uuid(),
         aff_code: fieldCode,
         aff_label: fieldLabel,
         description: fieldDesc,
         enable: fieldShow,
         afig_id: group,
         data_type: fieldType,
         required: fieldRequired,
         form_type: fieldFormType,
      }

      setFieldList([...fieldList, tmp]);
      setAddModalVisible(false);
   }

   const handleGetFieldList = () => {
      if (group) {
         setSearchLoading(true);

         GetFieldList();
      }
   }

   const onFinish = (values) => {
      console.log('Success:', values);
   };

   const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   const handleRemoveOption = (itm) => {
      const data = fieldOptions.filter(item => item.option !== itm);
      setFieldOptions(data);
   }

   const handleAddOption = () => {
      if (option) {
         setFieldOptions([...fieldOptions, { option: option }]);
         setOption(null);
      }
   }

   return (
      <>
         {/* <LeavePageBlocker when={hasChanges} /> */}
         {/* <FormPrompt hasUnsavedChanges={hasChanges} /> */}
         {/* <Prompt
            when={hasChanges}
            message='You have unsaved changes, are you sure you want to leave?'
         /> */}
         {/* <Prompt message="You have unsaved changes, are you sure you want to leave?" when={hasChanges} /> */}

         <ProcessingModal
            processing={updating}
            title={'Brainee LMS'}
         />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Management</Breadcrumb.Item>
                     <Breadcrumb.Item>Application Form Fields</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>

            <Row gutter={12} style={{ paddingBottom: "20px" }} >
               <Col span={24}>
                  <div className='search-columns-2'>
                     <Select
                        name='group'
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Section"
                        onChange={(val) => setGroup(val)}
                        value={group}
                     >
                        {groupList.map(({ afig_id, description }, idx) => {
                           return <Option value={afig_id}>{description}</Option>
                        })}
                     </Select>

                     <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleGetFieldList()} icon={<SearchOutlined />}>Search</Button>
                  </div>
               </Col>
            </Row>

            <Row gutter={12}>
               <Col className="gutter-row" span={24} style={{ textAlign: "left" }}>
                  <Space>
                     {
                        group !== null &&
                        <Button className='button-shadow' type="primary" size="primary" shape='round' onClick={() => handleAdd()}>
                           <Text ellipsis style={{ color: "#fff" }}><PlusOutlined />&nbsp;New Field</Text>
                        </Button>
                     }
                     {
                        fieldList.length > 0 &&
                        <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSave()}>
                           <Text ellipsis style={{ color: "#fff" }}><SaveOutlined />&nbsp;Save</Text>
                        </Button>
                     }

                  </Space>
               </Col>
            </Row>

            <Row gutter={12} style={{ marginTop: '15px' }} >
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     {
                        searchLoading
                           ?
                           <div className='center-empty-space'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                           :
                           (fieldList.length > 0 && !searchLoading)
                              ?
                              <Table
                                 // loading={searchLoading}
                                 size='small'
                                 columns={columns}
                                 dataSource={fieldList}
                                 pagination={false}
                                 scroll={{
                                    y: 'calc(100svh - 12rem)',
                                    x: '1000px'
                                 }}
                                 // title={() => <Space>
                                 //    {
                                 //       group !== null &&
                                 //       <Button className='button-shadow' type="primary" size="primary" shape='round' onClick={() => handleAdd()}>
                                 //          <Text ellipsis style={{ color: "#fff" }}><PlusOutlined />&nbsp;New Field</Text>
                                 //       </Button>
                                 //    }
                                 //    {
                                 //       fieldList.length > 0 &&
                                 //       <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSave()}>
                                 //          <Text ellipsis style={{ color: "#fff" }}><SaveOutlined />&nbsp;Save</Text>
                                 //       </Button>
                                 //    }

                                 // </Space>}
                                 footer={() => 'Total Records: ' + fieldList.length}
                                 rowKey="index"
                                 components={{
                                    body: {
                                       wrapper: DraggableContainer,
                                       row: DraggableBodyRow,
                                    },
                                 }}
                              />
                              :
                              <div className='center-empty-space'>
                                 <Empty image="../images/brainee_mascot.png" description="No records found" />
                              </div>
                     }

                  </div>
               </Col>
            </Row>
         </div>

         {/* Modals */}
         <Modal
            title={"Add New Field"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => { handleAddField() }}
            open={addModalVisible}
            // width='30%'
            style={{ minWidth: '400px', maxWidth: '500px' }}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Add'}
         >
            <Form
               form={form}
               layout={"vertical"}
               colon={false}
               className='fields-form'
               onFinish={onFinish}
               onFinishFailed={onFinishFailed}
            >
               <Row gutter={12}>
                  <Col span={12}>
                     <Form.Item name="field-code" label="Code"
                        rules={[{
                           required: true,
                           message: 'Field Code should not be blank'
                        }]}
                        initialValue={fieldCode}
                     >
                        <Input
                           name='fieldCode'
                           allowClear
                           size='middle'
                           style={{ borderRadius: 7 }}
                           placeholder="Enter Code"
                           onBlur={(event) => { setFieldCode(event.target.value) }}
                        />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item name="field-label" label="Label"
                        rules={[{
                           required: true,
                           message: 'Field Label should not be blank'
                        }]}
                        initialValue={fieldLabel}
                     >
                        <Input
                           name='fieldLabel'
                           allowClear
                           size='middle'
                           style={{ borderRadius: 7 }}
                           placeholder="Enter Label"
                           onBlur={(event) => { setFieldLabel(event.target.value) }}
                        />
                     </Form.Item>
                  </Col>
               </Row>

               <Row gutter={12}>
                  <Col span={12}>
                     <Form.Item name="field-desc" label="Message"
                        initialValue={fieldDesc}
                     >
                        <Input
                           name='fielddesc'
                           allowClear
                           size='middle'
                           style={{ borderRadius: 7 }}
                           placeholder="Enter Message"
                           onBlur={(event) => { setFieldDesc(event.target.value) }}
                        />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item name="field-location" label="Form Location"
                        initialValue={fieldLocation}
                     >
                        <Select
                           name='fieldLocation'
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Location"
                           onChange={(val) => setFieldLocation(val)}
                           value={fieldLocation}
                        >
                           <Select.Option value={0}>All</Select.Option>
                           <Select.Option value={1}>Form 1</Select.Option>
                           <Select.Option value={2}>Form 2</Select.Option>
                           <Select.Option value={3}>Form 3</Select.Option>
                        </Select>
                     </Form.Item>
                  </Col>
               </Row>

               {
                  fieldType === 'select'
                     ?
                     <Row gutter={12}>
                        <Col span={12}>
                           <Form.Item name="field-type" label="Type"
                              initialValue={fieldType}
                           >
                              <Select
                                 name='fieldType'
                                 showArrow
                                 size='middle'
                                 style={{ width: '100%' }}
                                 allowClear
                                 placeholder="Select Type"
                                 onChange={(val) => setFieldType(val)}
                                 defaultValue={fieldType}
                              >
                                 <Option value={'date'}>Date</Option>
                                 <Option value={'email'}>Email</Option>
                                 <Option value={'select'}>Select</Option>
                                 <Option value={'text'}>Text</Option>
                              </Select>
                           </Form.Item>
                        </Col>
                        <Col span={12}>
                           <Form.Item name="field-options" label="Options"
                              initialValue={fieldOptions}
                           >
                              <Table
                                 style={{ width: '100%', maxHeight: '120px' }}
                                 scroll={{
                                    y: '80px',
                                    // x: '1000px'
                                 }}
                                 name='fieldoptions'
                                 size='small'
                                 columns={[
                                    {
                                       // title: 'Options',
                                       key: 'option',
                                       render: payload => (
                                          <Text>{payload.option}</Text>
                                       ),
                                       width: '75%'
                                    },
                                    {
                                       // title: 'Action',
                                       key: 'action',
                                       render: payload => (
                                          <Space size="small">
                                             <Tooltip placement="top" title='Remove Option'>
                                                <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleRemoveOption(payload.option)} />
                                             </Tooltip>
                                          </Space>
                                       ),
                                       width: '20%'
                                    },
                                 ]}
                                 title={() => <Space>
                                    <Input
                                       name='addoption'
                                       allowClear
                                       size='small'
                                       style={{ borderRadius: 7 }}
                                       placeholder="Enter Option"
                                       onBlur={(event) => { setOption(event.target.value) }}
                                       defaultValue={option}
                                    />
                                    <Button className='button-shadow' type='primary' size='small' shape='round' block onClick={() => handleAddOption()}>
                                       <Text ellipsis style={{ color: "#fff" }}>Add</Text>
                                    </Button>
                                 </Space>}
                                 dataSource={fieldOptions}
                                 pagination={false}
                              />
                           </Form.Item>
                        </Col>
                     </Row>
                     :
                     <Form.Item name="field-type" label="Type"
                        initialValue={fieldType}
                     >
                        <Select
                           name='fieldType'
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           // placeholder="Select Type"
                           onChange={(val) => setFieldType(val)}
                           defaultValue={fieldType}
                        >
                           <Option value={'date'}>Date</Option>
                           <Option value={'email'}>Email</Option>
                           <Option value={'select'}>Select</Option>
                           <Option value={'text'}>Text</Option>
                        </Select>
                     </Form.Item>
               }
               <Row gutter={12}>
                  <Col span={12}>
                     <Form.Item name="field-required" label="Required"
                        initialValue={fieldRequired}
                     >
                        <Select
                           name='fieldRequired'
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           // placeholder="Select Type"
                           onChange={(val) => setFieldRequired(val)}
                           value={fieldRequired}
                        >
                           <Option value={1}>Yes</Option>
                           <Option value={0}>No</Option>
                        </Select>
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item name="field-form-type" label="Form Type"
                        initialValue={fieldFormType}
                     >
                        <Select
                           name='fieldFormType'
                           showArrow
                           size='middle'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select Form Type"
                           onChange={(val) => setFieldFormType(val)}
                           value={fieldFormType}
                        >
                           <Option value={'basic'}>Basic Form</Option>
                           <Option value={'full'}>Full Form</Option>
                        </Select>
                     </Form.Item>
                  </Col>
               </Row>

               {/* <Form.Item name="field-name-enable" label="Show in Application Form"
                  initialValue={fieldShow}
               >
                  <Select
                     name='fieldShow'
                     showArrow
                     size='middle'
                     style={{ width: '100%' }}
                     allowClear
                     placeholder="Select Type"
                     onChange={(val) => setFieldShow(val)}
                     value={fieldShow}
                  >
                     <Option value={1}>Yes</Option>
                     <Option value={0}>No</Option>
                  </Select>
               </Form.Item> */}
            </Form>
         </Modal>

         <Modal
            title={"Edit Field"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => { handleUpdate() }}
            open={editModalVisible}
            width='30%'
            style={{ minWidth: '400px', maxWidth: '500px' }} c
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Update'}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               <Form
                  form={form}
                  layout={"vertical"}
                  colon={false}
                  className='fields-form'
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
               >
                  <Row gutter={12}>
                     <Col span={12}>
                        <Form.Item name="field-code" label="Code"
                           rules={[{
                              required: true,
                              message: 'Field Code should not be blank'
                           }]}
                           initialValue={fieldCode}
                        >
                           <Input
                              name='fieldCode'
                              allowClear
                              size='middle'
                              style={{ borderRadius: 7 }}
                              placeholder="Enter Code"
                              onBlur={(event) => { setFieldCode(event.target.value) }}
                           />
                        </Form.Item>
                     </Col>
                     <Col span={12}>
                        <Form.Item name="field-label" label="Label"
                           rules={[{
                              required: true,
                              message: 'Field Label should not be blank'
                           }]}
                           initialValue={fieldLabel}
                        >
                           <Input
                              name='fieldname'
                              allowClear
                              size='middle'
                              style={{ borderRadius: 7 }}
                              placeholder="Enter Field Name"
                              onBlur={(event) => { setFieldLabel(event.target.value) }}
                           />
                        </Form.Item>
                     </Col>
                  </Row>

                  <Row gutter={12}>
                     <Col span={12}>
                        <Form.Item name="field-desc" label="Message"
                           initialValue={fieldDesc}
                        >
                           <Input
                              name='fielddesc'
                              allowClear
                              size='middle'
                              style={{ borderRadius: 7 }}
                              placeholder="Enter Message"
                              onBlur={(event) => { setFieldDesc(event.target.value) }}
                           />
                        </Form.Item>
                     </Col>
                     <Col span={12}>
                        <Form.Item name="field-location" label="Form Location"
                           initialValue={fieldLocation}
                        >
                           <Select
                              name='fieldLocation'
                              showArrow
                              size='middle'
                              style={{ width: '100%' }}
                              allowClear
                              placeholder="Select Location"
                              onChange={(val) => setFieldLocation(val)}
                              defaultValue={fieldLocation}
                           >
                              <Select.Option value={0}>All</Select.Option>
                              <Select.Option value={1}>Form 1</Select.Option>
                              <Select.Option value={2}>Form 2</Select.Option>
                              <Select.Option value={3}>Form 3</Select.Option>
                           </Select>
                        </Form.Item>
                     </Col>
                  </Row>

                  {
                     fieldType === 'select'
                        ?
                        <Row gutter={12}>
                           <Col span={12}>
                              <Form.Item name="field-type" label="Type"
                                 initialValue={fieldType}
                              >
                                 <Select
                                    name='fieldType'
                                    showArrow
                                    size='middle'
                                    style={{ width: '100%' }}
                                    allowClear
                                    placeholder="Select Type"
                                    onChange={(val) => setFieldType(val)}
                                    defaultValue={fieldType}
                                 >
                                    <Option value={'date'}>Date</Option>
                                    <Option value={'email'}>Email</Option>
                                    <Option value={'select'}>Select</Option>
                                    <Option value={'text'}>Text</Option>
                                 </Select>
                              </Form.Item>
                           </Col>
                           <Col span={12}>
                              <Form.Item name="field-options" label="Options"
                                 initialValue={fieldOptions}
                              >
                                 <Table
                                    style={{ width: '100%', maxHeight: '120px' }}
                                    scroll={{
                                       y: '80px',
                                       // x: '1000px'
                                    }}
                                    name='fieldoptions'
                                    size='small'
                                    columns={[
                                       {
                                          // title: 'Options',
                                          key: 'option',
                                          render: payload => (
                                             <Text>{payload.option}</Text>
                                          ),
                                          width: '75%'
                                       },
                                       {
                                          // title: 'Action',
                                          key: 'action',
                                          render: payload => (
                                             <Space size="small">
                                                <Tooltip placement="top" title='Remove Option'>
                                                   <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleRemoveOption(payload.option)} />
                                                </Tooltip>
                                             </Space>
                                          ),
                                          width: '20%'
                                       },
                                    ]}
                                    title={() => <Space>
                                       <Input
                                          name='addoption'
                                          allowClear
                                          size='small'
                                          style={{ borderRadius: 7 }}
                                          placeholder="Enter Option"
                                          onBlur={(event) => { setOption(event.target.value) }}
                                          defaultValue={option}
                                       />
                                       <Button className='button-shadow' type='primary' size='small' shape='round' block onClick={() => handleAddOption()}>
                                          <Text ellipsis style={{ color: "#fff" }}>Add</Text>
                                       </Button>
                                    </Space>}
                                    dataSource={fieldOptions}
                                    pagination={false}
                                 />
                              </Form.Item>
                           </Col>
                        </Row>
                        :
                        <Form.Item name="field-type" label="Type"
                           initialValue={fieldType}
                        >
                           <Select
                              name='fieldType'
                              showArrow
                              size='middle'
                              style={{ width: '100%' }}
                              allowClear
                              // placeholder="Select Type"
                              onChange={(val) => setFieldType(val)}
                              defaultValue={fieldType}
                           >
                              <Option value={'date'}>Date</Option>
                              <Option value={'email'}>Email</Option>
                              <Option value={'select'}>Select</Option>
                              <Option value={'text'}>Text</Option>
                           </Select>
                        </Form.Item>
                  }
                  <Row gutter={12}>
                     <Col span={12}>
                        <Form.Item name="field-required" label="Required"
                           initialValue={fieldRequired}
                        >
                           <Select
                              name='fieldRequired'
                              showArrow
                              size='middle'
                              style={{ width: '100%' }}
                              allowClear
                              // placeholder="Select Type"
                              onChange={(val) => setFieldRequired(val)}
                              defaultValue={fieldRequired}
                           >
                              <Option value={1}>Yes</Option>
                              <Option value={0}>No</Option>
                           </Select>
                        </Form.Item>
                     </Col>
                     <Col span={12}>
                        <Form.Item name="field-form-type" label="Form Type"
                           initialValue={fieldFormType}
                        >
                           <Select
                              name='fieldFormType'
                              showArrow
                              size='middle'
                              style={{ width: '100%' }}
                              allowClear
                              placeholder="Select Form Type"
                              onChange={(val) => setFieldFormType(val)}
                              defaultValue={fieldFormType}
                           >
                              <Option value={'basic'}>Basic Form</Option>
                              <Option value={'full'}>Full Form</Option>
                           </Select>
                        </Form.Item>
                     </Col>
                  </Row>
                  {/* <Form.Item name="field-enable" label="Show in Application Form"
                     initialValue={fieldShow}
                  >
                     <Select
                        name='fieldShow'
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Type"
                        onChange={(val) => setFieldShow(val)}
                        value={fieldShow}
                     >
                        <Option value={1}>Yes</Option>
                        <Option value={0}>No</Option>
                     </Select>
                  </Form.Item> */}
               </Form>
            </Spin>
         </Modal>
      </>
   );
}