import React, { useState, useEffect } from 'react';
import {
   Table, Space, Tooltip, Button, Select, Input, Row, Col, Tag,
   Modal, Typography, Spin, Breadcrumb, Popconfirm, Empty
} from 'antd';
import { SearchOutlined, SaveOutlined, EditOutlined, DeleteOutlined, PlusOutlined, MenuOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import uuid from 'react-uuid';
import ProcessingModal from '../../components/processing/ProcessingModal';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const { Text } = Typography;

const DragHandle = SortableHandle(() => (
   <MenuOutlined
      style={{
         cursor: 'grab',
         color: '#999',
      }}
   />
));

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

export function OnlineAppInfoGroups() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [groupList, setGroupList] = useState([]);
   const [searchLoading, setSearchLoading] = useState(false);

   const [updating, setUpdating] = useState(false);
   const [editModalVisible, setEditModalVisible] = useState(false);
   const [addModalVisible, setAddModalVisible] = useState(false);

   const [group, setGroup] = useState(null);
   const [groupCode, setGroupCode] = useState(null);
   const [groupLocation, setGroupLocation] = useState(0);
   const [groupFormType, setGroupFormType] = useState(null);
   const [groupDesc, setGroupDesc] = useState(null);

   const [hasChanges, setHasChanges] = useState(true);

   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const onSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
         const newData = arrayMoveImmutable(groupList.slice(), oldIndex, newIndex).filter(
            (el) => !!el,
         );
         console.log('Sorted items: ', newData);
         setGroupList(newData);
      }
   };
   const DraggableContainer = (props) => (
      <SortableBody
         useDragHandle
         disableAutoscroll
         helperClass="row-dragging"
         onSortEnd={onSortEnd}
         {...props}
      />
   );
   const DraggableBodyRow = ({ className, style, ...restProps }) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = groupList.findIndex((x) => x.index === restProps['data-row-key']);
      return <SortableItem index={index} {...restProps} />;
   };

   // useEffect(() => {
   //    const handleBeforeUnload = (event) => {
   //       if (hasChanges) {
   //          event.preventDefault();
   //          event.returnValue = '';
   //       }
   //    };

   //    window.addEventListener('beforeunload', handleBeforeUnload);

   //    return () => {
   //       window.removeEventListener('beforeunload', handleBeforeUnload);
   //    };
   // }, [hasChanges]);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher')
         navigate('/home');

      GetGroupList();
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: '',
         dataIndex: 'sort',
         width: '3%',
         className: 'drag-visible',
         render: () => <DragHandle />,
      },
      {
         title: 'Section Code',
         key: 'afig_code',
         ...getColumnSearchProps('afig_code'),
         ellipsis: {
            showTitle: false,
         },
         className: 'drag-visible',
         render: payload => (
            <Tooltip placement="topLeft" title={payload.afig_code}>
               <Text>
                  {
                     payload.afig_code
                  }
               </Text>
            </Tooltip>
         ),
         width: '25%'
      },
      {
         title: 'Section Label',
         key: 'description',
         ...getColumnSearchProps('description'),
         ellipsis: {
            showTitle: false,
         },
         className: 'drag-visible',
         render: payload => (
            <Tooltip placement="topLeft" title={payload.description}>
               <Text>
                  {
                     payload.description
                  }
               </Text>
            </Tooltip>
         ),
         width: '25%'
      },
      {
         title: 'Form Location',
         key: 'afig_location',
         ...getColumnSearchProps('afig_location'),
         ellipsis: {
            showTitle: false,
         },
         className: 'drag-visible',
         render: payload => (
            <Text>
               {
                  Utils.GetFormLocation(payload.afig_location)
               }
            </Text>
         ),
         width: '15%'
      },
      {
         title: 'Form Type',
         key: 'afig_form_type',
         ...getColumnSearchProps('afig_form_type'),
         ellipsis: {
            showTitle: false,
         },
         className: 'drag-visible',
         render: payload => (
            <Text>
               {
                  payload.afig_form_type === 'basic' ? 'Basic Form' : (payload.afig_form_type === "" ? "" : 'Full Form')
               }
            </Text>
         ),
         width: '17%'
      },
      {
         title: 'Action',
         key: 'action',
         className: 'drag-visible',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit Group'>
                     <EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEdit(payload.afig_id, payload.description, payload.afig_code, payload.afig_location, payload.afig_form_type)} />
                  </Tooltip>
                  <Tooltip placement="top" title='Remove Group'>
                     <Popconfirm
                        title="Are you sure you want to remove this item?"
                        onConfirm={() => handleRemove(payload.afig_id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
               </Space>
            </>
         },
         width: '15%'
      },
   ].filter(item => !item.hidden);

   const GetGroupList = () => {
      setSearchLoading(true);

      axios.get(`${process.env.REACT_APP_API_MANAGEMENT}/getappinfogrouplist`).then((response) => {
         if (response.data) {
            const data = response.data;

            //-- Add index to the array for sorting
            data.forEach((item, index) => {
               item.index = index;
               return item;
            });

            setGroupList(data);
            setSearchLoading(false);
         }
      }, (error) => {
         setSearchLoading(false);
         console.log(error.message);
      });
   }

   const handleAddNewGroup = () => {
      setAddModalVisible(true);
   }

   const handleEdit = (id, desc, code, location, form_type) => {
      setGroup(id);
      setGroupCode(code);
      setGroupDesc(desc);
      setGroupLocation(location);
      setGroupFormType(form_type);
      setEditModalVisible(true);
   }

   const handleSave = () => {
      if (groupList.length > 0) {
         setUpdating(true);

         let url = `${process.env.REACT_APP_API_MANAGEMENT}/upsertappinfogroup`;
         axios.post(url, groupList).then((response) => {
            if (response.data) {
               const data = response.data;

               // GetGroupList();
               setUpdating(false);

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Application Form Management',
                     content: 'There was a problem encountered while processing your request.',
                     centered: true,
                  });
               }
               else {
                  Modal.success({
                     title: 'Application Form Management',
                     content: 'Creating new section successful.',
                     centered: true,
                     onOk: () => { handleCancel() },
                  });
               }
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Application Form Management',
               content: 'There was a problem encountered while processing your request.',
               centered: true,
            });
         });
      } else {
         Modal.info({
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        New Application Section
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }

   }

   const handleUpdate = () => {
      if (groupList.length > 0) {

         let tmp = [...groupList];
         tmp.forEach(item => {
            if (item.afig_id === group) {
               item.description = groupDesc;
               item.afig_code = groupCode;
               item.afig_location = groupLocation;
               item.afig_form_type = groupFormType;
            }
         });

         setGroupList(tmp);
         setEditModalVisible(false);
      } else {
         Modal.info({
            title: 'Application Form Management',
            content: (
               <>
                  Please complete the folowing before saving:
                  <ul>
                     <li>
                        New Application Section
                     </li>
                  </ul>
               </>),
            centered: true,
         });
      }
   }

   const handleCancel = () => {
      setEditModalVisible(false);
      setAddModalVisible(false);
   }

   const handleRemove = (id) => {
      if (groupList.length > 0) {

         let url = `${process.env.REACT_APP_API_MANAGEMENT}/removeappinfogroup?id=${id}`;
         axios.post(url).then((response) => {
            if (response.data) {
               const data = response.data;

               if (data.Code === 1) {
                  Modal.error({
                     title: 'Application Form Management',
                     content: 'There was a problem encountered while processing your request.',
                     centered: true,
                  });
               }
               else {
                  Modal.success({
                     title: 'Application Form Management',
                     content: 'Remove successful.',
                     centered: true,
                     onOk: () => { handleCancel() },
                  });
               }

               const tmp = groupList.filter(item => item.afig_id !== id);
               setGroupList(tmp);
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Application Form Management',
               content: 'There was a problem encountered while processing your request.',
               centered: true,
            });
         });
      }
   }

   const handleAddGroup = () => {
      // let tmp = [...groupList]
      // tmp.push({
      //    value: uuid(),
      //    name: name
      // });

      // setGroupList(tmp);

      let tmp = {
         afig_id: uuid(),
         afig_code: groupCode,
         description: groupDesc,
         afig_location: groupLocation,
         afig_form_type: groupFormType,
      }

      setGroupList([...groupList, tmp]);
      setAddModalVisible(false);
   }

   return (
      <>
         <ProcessingModal
            processing={updating}
            title={'Brainee LMS'}
         />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Management</Breadcrumb.Item>
                     <Breadcrumb.Item>Application Form Sections</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>

            <Row gutter={12}>
               <Col className="gutter-row" span={24} style={{ textAlign: "left" }}>
                  <Space>
                     <Button className='button-shadow' type="primary" size="primary" shape='round' onClick={() => handleAddNewGroup()}>
                        <Text ellipsis style={{ color: "#fff" }}><PlusOutlined />&nbsp;New Section</Text>
                     </Button>
                     {
                        groupList.length > 0 &&
                        <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSave()}>
                           <Text ellipsis style={{ color: "#fff" }}><SaveOutlined />&nbsp;Save</Text>
                        </Button>
                     }

                  </Space>
               </Col>
            </Row>

            <Row gutter={12} style={{ marginTop: '15px' }} >
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     {
                        searchLoading
                           ?
                           <div className='center-empty-space'>
                              <Spin size='large' tip="Loading, please wait..." />
                           </div>
                           :
                           (groupList.length > 0 && !searchLoading)
                              ?
                              <Table
                                 // loading={searchLoading}
                                 size='small'
                                 columns={columns}
                                 dataSource={groupList}
                                 pagination={false}
                                 scroll={{
                                    y: 'calc(100svh - 12rem)',
                                    x: '1000px'
                                 }}
                                 footer={() => 'Total Records: ' + groupList.length}
                                 rowKey="index"
                                 components={{
                                    body: {
                                       wrapper: DraggableContainer,
                                       row: DraggableBodyRow,
                                    },
                                 }}
                              />
                              :
                              <div className='center-empty-space'>
                                 <Empty image="../images/brainee_mascot.png" description="No records found" />
                              </div>
                     }

                  </div>
               </Col>
            </Row>
         </div>

         {/* Modals */}
         <Modal
            title={"Add Section"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => { handleAddGroup() }}
            open={addModalVisible}
            width='35%'
            style={{ minWidth: '300px' }}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Add'}
         >
            <Space direction='vertical' style={{ width: '100%' }}>
               <span>Code</span>
               <Input
                  allowClear
                  size='middle'
                  style={{ borderRadius: 7 }}
                  placeholder="Enter Code"
                  onBlur={(event) => { setGroupCode(event.target.value) }}
               />
               <span>Label</span>
               <Input
                  allowClear
                  size='middle'
                  style={{ borderRadius: 7 }}
                  placeholder="Enter Label"
                  onBlur={(event) => { setGroupDesc(event.target.value) }}
               />
               <span>Form Location</span>
               <Select
                  name='formLocation'
                  showArrow
                  size='middle'
                  style={{ width: '100%' }}
                  allowClear
                  placeholder="Select Location"
                  onChange={(val) => setGroupLocation(val)}
                  value={groupLocation}
               >
                  <Select.Option value={0}>All</Select.Option>
                  <Select.Option value={1}>Form 1</Select.Option>
                  <Select.Option value={2}>Form 2</Select.Option>
                  <Select.Option value={3}>Form 3</Select.Option>
               </Select>
               <span>Form Type</span>
               <Select
                  name='groupFormType'
                  showArrow
                  size='middle'
                  style={{ width: '100%' }}
                  allowClear
                  placeholder="Select Form Type"
                  onChange={(val) => setGroupFormType(val)}
                  value={groupFormType}
               >
                  <Select.Option value={'basic'}>Basic Form</Select.Option>
                  <Select.Option value={'full'}>Full Form</Select.Option>
               </Select>
            </Space>
         </Modal>

         <Modal
            title={"Edit Section"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancel(); }}
            onOk={() => { handleUpdate() }}
            open={editModalVisible}
            width='35%'
            style={{ minWidth: '300px' }}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            okText={'Update'}
         >
            <Spin spinning={updating} tip={"Updating, please wait..."}>
               <Space direction='vertical' style={{ width: '100%' }}>
                  <span>Code</span>
                  <Input
                     allowClear
                     size='middle'
                     style={{ borderRadius: 7 }}
                     placeholder="Enter Code"
                     onBlur={(event) => { setGroupCode(event.target.value) }}
                     defaultValue={groupCode}
                  />
                  <span>Label</span>
                  <Input
                     allowClear
                     size='middle'
                     style={{ borderRadius: 7 }}
                     placeholder="Enter Label"
                     onBlur={(event) => { setGroupDesc(event.target.value) }}
                     defaultValue={groupDesc}
                  />
                  <span>Form Location</span>
                  <Select
                     name='formLocation'
                     showArrow
                     size='middle'
                     style={{ width: '100%' }}
                     allowClear
                     placeholder="Select Location"
                     onChange={(val) => setGroupLocation(val)}
                     defaultValue={groupLocation}
                  >
                     <Select.Option value={0}>All</Select.Option>
                     <Select.Option value={1}>Form 1</Select.Option>
                     <Select.Option value={2}>Form 2</Select.Option>
                     <Select.Option value={3}>Form 3</Select.Option>
                  </Select>
                  <span>Form Type</span>
                  <Select
                     name='groupFormType'
                     showArrow
                     size='middle'
                     style={{ width: '100%' }}
                     allowClear
                     placeholder="Select Form Type"
                     onChange={(val) => setGroupFormType(val)}
                     defaultValue={groupFormType}
                  >
                     <Select.Option value={'basic'}>Basic Form</Select.Option>
                     <Select.Option value={'full'}>Full Form</Select.Option>
                  </Select>
               </Space>
            </Spin>
         </Modal>
      </>
   );
}