import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Table, Typography, Space, Tooltip, Button, Badge, Row, Col, Tag, Spin, Popconfirm, Modal, DatePicker, Image } from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import moment from 'moment';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';

const { Text, Link } = Typography;

export function ApplicationList() {
   const navigate = useNavigate();
   const [searchText, setSearchText] = useState("");
   const [searchedColumn, setSearchedColumn] = useState("");

   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const customFormat = value => `${value.format('MMM. D, YYYY h:mm a')}`;
   const subdomain = localStorage.getItem("lms-subdomain");

   const [legendCount, setLegendCount] = useState({
      pending: 0,
      accepted: 0,
      not_accepted: 0,
      ready_for_exam: 0,
      passed: 0,
      failed: 0,
      approved: 0,
      not_approved: 0,
      for_interview: 0,
      reconsidered: 0,
      rejected: 0,
      for_assessment: 0
   });

   const [applicantList, setApplicantList] = useState([]);
   const [applicantListFiltered, setApplicantListFiltered] = useState([]);
   const [listLoading, setListLoading] = useState(false);
   const [updating, setUpdating] = useState(false);
   const [schedule, setSchedule] = useState(null);
   const [applicantID, setApplicantID] = useState(null);
   const [validPoP, setValidPoP] = useState(false);
   const [docToView, setDocToView] = useState(null);
   const [bankDetails, setBankDetails] = useState(null);

   const [modalSchedule, setModalSchedule] = useState(false);
   const [modalPoPValidate, setModalPoPValidate] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      window.addEventListener('error', e => {
         if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
            const resizeObserverErrDiv = document.getElementById(
               'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
               'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
               resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
               resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
         }
      });

      getApplicants();
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
               </Button>
               <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'Last Name',
         key: 'last_name',
         sorter: (a, b) => { return a.last_name.localeCompare(b.last_name) },
         ...getColumnSearchProps('last_name'),
         ellipsis: {
            showTitle: false,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.last_name}>
               <Text><span style={{ textTransform: 'capitalize' }}>{object.last_name}</span></Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'First Name',
         key: 'first_name',
         sorter: (a, b) => { return a.first_name.localeCompare(b.first_name) },
         ...getColumnSearchProps('first_name'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.first_name}>
               <Text><span style={{ textTransform: 'capitalize' }}>{object.first_name}</span></Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Middle Name',
         key: 'middle_name',
         sorter: (a, b) => { return a.middle_name.localeCompare(b.middle_name) },
         ...getColumnSearchProps('middle_name'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.middle_name}>
               <Text><span style={{ textTransform: 'capitalize' }}>{object.middle_name}</span></Text>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Gender',
         key: 'gender',
         sorter: (a, b) => { return a.gender.localeCompare(b.gender) },
         ...getColumnSearchProps('gender'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Text>
               {
                  object.gender !== null
                     ?
                     <span style={{ textTransform: 'capitalize' }}>{object.gender}</span>
                     :
                     <></>
               }
            </Text>
         ),
         width: '8%'
      },
      {
         title: 'Applying For',
         key: 'applying_for',
         ...getColumnSearchProps('applying_for'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            object.applying_for !== null && object.applying_for !== '' &&
            <Text>{object.applying_for}</Text>
         ),
         width: '10%'
      },
      {
         title: 'Date Applied',
         key: 'date_applied',
         sorter: (a, b) => { return a.date_applied.localeCompare(b.date_applied) },
         render: object => (
            <Text>
               {moment(object.date_applied).format('MMM. DD, YYYY hh:mm A')}
            </Text>
         ),
         width: '12%'
      },
      {
         title: 'Application Status',
         key: 'application_status',
         ...getColumnSearchProps('application_status'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <>
               {
                  ((object.exam_status === null || object.exam_status === '') && object.application_status !== 'approved' && object.application_status !== 'for_assessment' && object.application_status !== 'ready_for_exam' && object.is_pop_valid) &&
                  RenderTag('paid')
               }
               {
                  (object.exam_status !== null && object.exam_status !== '' && (object.application_status === 'approved' || object.application_status === 'for_assessment' || object.application_status === 'on_hold')) &&
                  RenderTag(object.exam_status)
               }

               {RenderTag(object.application_status)}
            </>

         ),
         width: '22%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return (
               <>
                  <Space size="small">
                     {
                        (user_role === 'Cashier') &&
                        <Button type='primary' size='small' shape='round' onClick={() => handleViewDoc(payload.id, payload.bank_details)}><EyeOutlined /> Proof of Payment</Button>
                     }
                     {
                        ((user_role === 'Interviewer' || user_role === 'Registrar') && payload.application_status === 'approved') &&
                        <Button type='primary' size='small' shape='round' onClick={() => handleInterview(payload.id, 'view')}>Interview Result</Button>
                     }
                     {
                        (payload.application_status !== 'approved' && user_role === 'Registrar') &&
                        <Button type='primary' size='small' shape='round' onClick={() => handleReviewApplication(payload.id, payload.application_status)}>{payload.application_status === 'pending' ? 'Review' : 'View'}</Button>
                        // <>
                        //    {
                        //       payload.application_status === 'approved'
                        //          ?
                        //          <Button type='primary' size='small' shape='round' onClick={() => handleInterview(payload.id, 'view')}>Interview Result</Button>
                        //          :
                        //          <Button type='primary' size='small' shape='round' onClick={() => handleReviewApplication(payload.id, payload.application_status)}>{payload.application_status === 'pending' ? 'Review' : 'View'}</Button>
                        //    }
                        // </>
                     }
                     {
                        (payload.application_status === 'rejected' && user_role === 'Registrar') &&
                        <Popconfirm
                           title="Do you want to Unreject this applicant?"
                           onConfirm={() => handleUnreject(payload.id)}
                           okText="Yes"
                           cancelText="No"
                        >
                           <Button type='primary' size='small' shape='round'>Unreject</Button>
                        </Popconfirm>

                     }
                     {
                        (payload.application_status === 'exam_failed' && user_role === 'Registrar') &&
                        <Popconfirm
                           title="Do you want to reconsider this applicant?"
                           onConfirm={() => handleReconsider(payload.id)}
                           okText="Yes"
                           cancelText="No"
                        >
                           <Button type='primary' size='small' shape='round'>Reconsider</Button>
                        </Popconfirm>
                     }
                     {
                        ((payload.application_status === 'exam_passed' || payload.application_status === 'reconsidered') && user_role === 'Registrar') &&
                        <>
                           <Button type='primary' size='small' shape='round' onClick={() => handleProceedAssessment(payload.id, true)}>Proceed with Assessment</Button>
                        </>

                     }
                     {
                        ((payload.application_status === 'exam_passed' || payload.application_status === 'reconsidered') && user_role === 'Interviewer') &&
                        <>
                           <Button type='primary' size='small' shape='round' onClick={() => handleReviewApplication(payload.id, payload.application_status)}>View</Button>
                           <Button type='primary' size='small' shape='round' onClick={() => showModalSchedule(payload.id, true)}>Set Interview</Button>
                        </>

                     }
                     {
                        (payload.application_status === 'on_hold' || payload.application_status === 'for_interview') && user_role === 'Interviewer' &&
                        <>
                           {
                              payload.interview_ready === true
                                 ?
                                 <>
                                    {
                                       payload.application_status !== 'on_hold'
                                          ?
                                          <>
                                             <Button type='primary' size='small' shape='round' onClick={() => handleInterview(payload.id)}>Interview</Button>
                                             <Button type='primary' size='small' shape='round' onClick={() => showModalSchedule(payload.id, true)}>Re-schedule Interview</Button>
                                          </>
                                          :
                                          <Button type='primary' size='small' shape='round' onClick={() => handleInterview(payload.id, 'edit-view')}>Interview</Button>
                                    }
                                 </>
                                 :
                                 <Button type='primary' size='small' shape='round'>Schedule: {payload.interview_schedule}</Button>
                           }

                        </>
                     }
                  </Space>
               </>
            );
         },
         width: '19%'
      },
   ].filter(item => !item.hidden);

   const getApplicants = () => {
      setListLoading(true);
      axios.get(`${process.env.REACT_APP_API_CPH}/getonlineapplicants`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            //--Update legend counts
            var loclegend = {
               pending: 0,
               accepted: 0,
               rejected: 0,
               ready_for_exam: 0,
               passed: 0,
               failed: 0,
               approved: 0,
               not_approved: 0,
               for_interview: 0,
               reconsidered: 0,
               for_assessment: 0
            }

            for (var i = 0; i < data.length; i++) {
               switch (data[i].application_status) {
                  case 'pending':
                     loclegend.pending++;
                     // setLegendCount(...legend, pending: loclegend.pending);
                     break;
                  case 'accepted':
                     loclegend.accepted++;
                     break;
                  case 'rejected':
                     loclegend.rejected++;
                     break;
                  case 'ready_for_exam':
                     loclegend.ready_for_exam++;
                     break;
                  case 'exam_passed':
                     loclegend.passed++;
                     break;
                  case 'exam_failed':
                     loclegend.failed++;
                     break;
                  case 'approved':
                     loclegend.approved++;
                     break;
                  case 'not_approved':
                     loclegend.not_approved++;
                     break;
                  case 'on_hold':
                  case 'for_interview':
                     loclegend.for_interview++;
                     break;
                  case 'for_assessment':
                     loclegend.for_assessment++;
                     break;
                  case 'reconsidered':
                     loclegend.reconsidered++;
                     break;
                  default:
               }
            }

            setLegendCount({ ...loclegend });

            setApplicantList(data);
            setApplicantListFiltered(data);
            setListLoading(false);
         }
      }, (error) => {
         setListLoading(false);
         console.log(error.message);
      });
   }

   const RenderTag = (status) => {
      var color = '';

      switch (status) {
         case 'pending':
            color = 'gold';
            break;
         case 'accepted':
            color = 'green'
            break;
         case 'rejected':
            color = 'red';
            break;
         case 'ready_for_exam':
            color = 'pink';
            break;
         case 'exam_passed':
            color = 'green';
            break;
         case 'exam_failed':
            color = 'red';
            break;
         case 'approved':
            color = 'cyan';
            break;
         case 'not_approved':
            color = 'red';
            break;
         case 'on_hold':
         case 'for_interview':
            color = 'blue';
            break;
         case 'for_assessment':
            color = 'blue';
            break;
         case 'reconsidered':
            color = 'purple';
            break;
         case 'paid':
            color = 'cyan';
            break;
         default:
      }

      return <>
         <Tag color={color}>
            {
               status === 'on_hold'
                  ?
                  'INTERVIEW ' + status.replace(/_/g, ' ').toUpperCase()
                  :
                  status.replace(/_/g, ' ').toUpperCase()
            }
         </Tag>
      </>
   }

   const RenderStatusCounts = () => {
      return <>
         <div style={{ overflow: 'auto' }}>
            <Row style={{ paddingTop: "20px", minWidth: '600px', marginBottom: '20px' }} >
               <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Space size={'middle'}>
                     <Badge count={legendCount.pending} style={{ backgroundColor: 'gold' }} overflowCount={999}>
                        <Tag
                           color={"gold"}
                           style={{ cursor: legendCount.pending > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('pending')}
                        >
                           PENDING
                        </Tag>
                     </Badge>
                     <Badge count={legendCount.accepted} style={{ backgroundColor: 'green' }} overflowCount={999}>
                        <Tag
                           color={"green"}
                           style={{ cursor: legendCount.accepted > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('accepted')}
                        >
                           ACCEPTED
                        </Tag>
                     </Badge>
                     <Badge count={legendCount.rejected} style={{ backgroundColor: 'red' }} overflowCount={999}>
                        <Tag
                           color={"red"}
                           style={{ cursor: legendCount.rejected > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('rejected')}
                        >
                           REJECTED
                        </Tag>
                     </Badge>
                     {/* <Badge count={legendCount.rejected} style={{ backgroundColor: 'red' }} overflowCount={999}>
                        <Tag
                           color={"red"}
                           style={{ cursor: legendCount.rejected > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('rejected')}
                        >
                           REJECTED
                        </Tag>
                     </Badge> */}
                     <Badge count={legendCount.ready_for_exam} style={{ backgroundColor: 'pink' }} overflowCount={999}>
                        <Tag
                           color={"pink"}
                           style={{ cursor: legendCount.ready_for_exam > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('ready_for_exam')}
                        >
                           READY FOR EXAM
                        </Tag>
                     </Badge>
                     <Badge count={legendCount.passed} style={{ backgroundColor: 'green' }} overflowCount={999}>
                        <Tag
                           color={"green"}
                           style={{ cursor: legendCount.passed > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('exam_passed')}
                        >
                           EXAM PASSED
                        </Tag>
                     </Badge>
                     <Badge count={legendCount.failed} style={{ backgroundColor: 'red' }} overflowCount={999}>
                        <Tag
                           color={"red"}
                           style={{ cursor: legendCount.failed > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('exam_failed')}
                        >
                           EXAM FAILED
                        </Tag>
                     </Badge>
                     <Badge count={legendCount.reconsidered} style={{ backgroundColor: 'purple' }} overflowCount={999}>
                        <Tag
                           color={"purple"}
                           style={{ cursor: legendCount.reconsidered > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('reconsidered')}
                        >
                           RECONSIDERED
                        </Tag>
                     </Badge>
                     {/* <Badge count={legendCount.for_interview} style={{ backgroundColor: 'blue' }} overflowCount={999}>
                        <Tag
                           color={"blue"}
                           style={{ cursor: legendCount.for_interview > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('for_interview')}
                        >
                           FOR INTERVIEW
                        </Tag>
                     </Badge> */}
                     <Badge count={legendCount.for_assessment} style={{ backgroundColor: 'blue' }} overflowCount={999}>
                        <Tag
                           color={"blue"}
                           style={{ cursor: legendCount.for_assessment > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('for_assessment')}
                        >
                           FOR ASSESSMENT
                        </Tag>
                     </Badge>
                     <Badge count={legendCount.approved} style={{ backgroundColor: 'cyan' }} overflowCount={999}>
                        <Tag
                           color={"cyan"}
                           style={{ cursor: legendCount.approved > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('approved')}
                        >
                           APPROVED
                        </Tag>
                     </Badge>
                     {/* <Badge count={legendCount.not_approved} style={{ backgroundColor: 'red' }} overflowCount={999}>
                        <Tag
                           color={"red"}
                           style={{ cursor: legendCount.not_approved > 0 ? 'pointer' : 'default', padding: '7px' }}
                           onClick={() => handleFilterListByStatus('not_approved')}
                        >
                           NOT APPROVED
                        </Tag>
                     </Badge> */}
                     <Button className='button-shadow' type='primary' shape='round' onClick={() => getApplicants()}>Refresh</Button>
                  </Space>
               </Col>
            </Row>
         </div>
      </>
   }

   const handleFilterListByStatus = (status) => {
      var filteredList = applicantList.filter(student => student.application_status === status);

      setApplicantListFiltered(filteredList);
   }

   const handleReviewApplication = (id, status) => {
      navigate(`/reviewapplication?id=${id}`, { state: { status } });
   }

   const handleReconsider = (id) => {
      setUpdating(true);
      var url = `${process.env.REACT_APP_API_CPH}/reconsiderapplicant?id=${id}`;

      axios.post(url).then((response) => {
         if (response.data) {
            setUpdating(false);

            Modal.success({
               title: 'Online Application',
               content: 'Reconsider successful.',
               centered: true,
               onOk: getApplicants(),
            });
         }
      }, (error) => {
         setUpdating(false);

         Modal.error({
            title: 'Online Application',
            content: 'Reconsider failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const showModalSchedule = (applicant_id, show) => {
      setApplicantID(applicant_id);
      setModalSchedule(show);
   }

   const handleSetInterviewSchedule = () => {
      setModalSchedule(false);
      setUpdating(true);
      var url = `${process.env.REACT_APP_API_CPH}/setinterviewschedule?id=${applicantID}&sched=${moment(schedule).format("YYYY-MM-DD HH:mm:ss")}`;

      axios.post(url).then((response) => {
         if (response.data) {
            setModalSchedule(false);
            setUpdating(false);
            Modal.success({
               title: 'Online Application',
               content: 'Setting interview schedule successful.',
               centered: true,
               onOk: getApplicants(),
            });
         }
      }, (error) => {
         setModalSchedule(false);
         setUpdating(false);
         Modal.error({
            title: 'Online Application',
            content: 'Setting interview schedule failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleInterview = (id, mode = 'edit') => {
      navigate(`/interview?id=${id}`, { state: { mode } });
   }

   const handleViewDoc = (id, bank_details) => {
      //-- Get proof of payment url from documents
      var items = applicantListFiltered.filter(item => item.id === id);
      let docs = JSON.parse(items[0].documents);

      setBankDetails(bank_details);
      setDocToView(docs.proof_of_payment);
      setApplicantID(id);
      setModalPoPValidate(true);
   }

   const handleMarkAsPaid = (val) => {
      setUpdating(true);

      var url = `${process.env.REACT_APP_API_CPH}/markaspaid?id=${applicantID}&v=${val}`;

      axios.post(url).then((response) => {
         if (response.data) {
            setModalPoPValidate(false);
            setUpdating(false);
            Modal.success({
               title: 'Online Application',
               content: 'Setting mark as paid successful.',
               centered: true,
               onOk: getApplicants(),
            });
         }
      }, (error) => {
         setModalPoPValidate(false);
         setUpdating(false);
         Modal.error({
            title: 'Online Application',
            content: 'Setting mark as paid failed.',
            centered: true
         });
      });
   }

   const handleUnreject = (id) => {
      setUpdating(true);
      var url = `${process.env.REACT_APP_API_CPH}/changestatus?id=${id}&ns=pending`;

      axios.post(url).then((response) => {
         if (response.data) {
            setUpdating(false);
            Modal.success({
               title: 'Online Application',
               content: 'Unreject successful.',
               centered: true,
               onOk: getApplicants(),
            });
         }
      }, (error) => {
         setUpdating(false);
         Modal.error({
            title: 'Online Application',
            content: 'Unreject failed.',
            centered: true
         });
      });
   }

   const handleProceedAssessment = (id) => {
   };

   return (
      <>
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <Spin size='large' spinning={updating} tip="Processing request...Please wait.">
                  <div className="dashboard-item-inner">
                     <ul>
                        <li className="active">Student Applicants Tracker</li>
                     </ul>
                  </div>

                  <div className="dashboard-item-inner3">
                     {
                        RenderStatusCounts()
                     }
                     <Table
                        loading={listLoading}
                        size='small'
                        rowKey={'uid'}
                        columns={columns}
                        dataSource={applicantListFiltered}
                        pagination={false}
                        scroll={{
                           x: '1000px',
                           y: 'calc(100vh - 17.5rem)',
                        }}
                        footer={() => 'Total Records: ' + applicantListFiltered.length}
                     />
                  </div>
               </Spin>
            </div>
         </div>

         <Modal
            key="modal-schedule"
            title='Online Application'
            destroyOnClose={true}
            centered
            okText={"Set"}
            cancelText={"Close"}
            open={modalSchedule}
            okButtonProps={{ shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
            onOk={() => handleSetInterviewSchedule()}
            onCancel={() => setModalSchedule(false)}
         >
            <Text style={{ verticalAlign: "middle" }}>Set Interview Schedule</Text>
            <DatePicker
               placeholder="Select Date"
               showTime={{ format: 'hh:mm A', use12Hours: true }}
               format={customFormat}
               style={{ width: "100%" }}
               // disabledDate={(current) => current && current < moment().endOf('day')}
               //disabledDate={(current) => current < moment().endOf('day')}
               disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
               onBlur={(event) => setSchedule(event.target.value)}
            />
         </Modal>

         <Modal
            key='pop-validate'
            title={"Validate Proof of Payment"}
            okText={"Paid"}
            cancelText={'Not Paid'}
            okButtonProps={{ type: 'primary', shape: "round" }}
            cancelButtonProps={{ type: 'primary', shape: 'round' }}
            centered
            destroyOnClose={true}
            open={modalPoPValidate}
            width='75%'

            // onCancel={() => { handleMarkAsPaid(false); }}
            // onOk={() => { handleMarkAsPaid(true) }}

            footer={[
               <Button key="paid" type='primary' shape='round' onClick={() => handleMarkAsPaid(true)}>Paid</Button>,
               <Button key="notpaid" type='primary' shape='round' onClick={() => handleMarkAsPaid(false)}>Not Paid</Button>,
               <Button key="close" type="primary" shape='round' onClick={() => setModalPoPValidate(false)}>Close</Button>
            ]}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               <div
                  className={'modal-document-viewer'}
                  style={{ height: "calc(100vh - 220px)" }}
               >
                  <Image
                     // src={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${docToView}`}
                     src={docToView}
                     style={{ minHeight: "calc(100vh - 220px)", maxHeight: "calc(100vh - 220px)" }}
                  />

               </div>
               <div><Text>Bank Details: <b>{bankDetails}</b></Text></div>
            </div>
         </Modal>
      </>
   );
}
